// import React from 'react';
// import './App.css';
// import { BrowserRouter as Router } from 'react-router-dom';
// import Routes from "./Routes";
// import EmpStateContext from './context/employee/EmpState';

// function App() {
//   return (
//     <EmpStateContext>
//         <Router>
//           <Routes />
//         </Router>
//       </EmpStateContext>
//   );
// }

// export default App;


import React from 'react';
import './App.css';
import Routes from './Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';
import { config } from './Config/Config';
import logo from '../src/images/logo.svg';
import EmpStateContext from './context/employee/EmpState';
import { Button, Navbar, Nav, Form, Container, Col, Row, Image } from "react-bootstrap";
import InstallButton from './Home/InstallButton';



class App extends React.Component {
    state = {
        isDisconnected: false,
        onLoad: false,
        isMounted: false,
        email: ""
    }

    componentDidMount() {
        console.log("componentDidMount")
        this.handleConnectionChange();
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
        // if (!this.getUserLoggedIn) {
        //     document.cookie = "userLoggedIn=true; expires = 0"
        //     window.addEventListener("beforeunload", this.logoutLocal);

        // }
    }
    getUserLoggedIn = () => {
        var x = document.cookie;
        if (x.split('=')[0] == "userLoggedIn") {
            return x.split('=')[1]
        }
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    componentWillUnmount() {
        // console.log("componentWillUnmount")
        // this.setState({ onLoad: true })
        // window.removeEventListener('online', this.handleConnectionChange);
        // window.removeEventListener('offline', this.handleConnectionChange);
        // window.removeEventListener("beforeunload", this.logoutLocal);
    }

    handleConnectionChange = () => {
        const condition = navigator.onLine ? 'online' : 'offline';
        if (condition === 'online') {
            const webPing = setInterval(
                () => {
                    fetch('//google.com', {
                        mode: 'no-cors',
                    })
                        .then(() => {
                            this.setState({ isDisconnected: false }, () => {
                                return clearInterval(webPing)
                            });
                        }).catch(() => this.setState({ isDisconnected: true }))
                }, 2000);
            return;
        }

        return this.setState({ isDisconnected: true });
    }
    render() {
        const { isDisconnected } = this.state;
        const width = window.innerWidth
        return (
            <div>{width < 500 ?
                <div > <center id="turn">
                    <h4 className="no-connection">Works on portrait mode, please rotate your decice</h4>
                </center>{isDisconnected ?
                    <center className="no-connection"> <h4>No Internet connection</h4></center>
                    : <div id="container">
                        <InstallButton />
                        <EmpStateContext>
                            <Router>
                                <Routes />
                            </Router>
                        </EmpStateContext>
                    </div >}
                </div> : <div><Container>
                    <Row>
                        <Col md={1}></Col>
                        <Col md={5}>
                            <img src={logo} style={{ marginTop: "45%" }} />
                        </Col>
                        <Col md={5} style={{ marginTop: "13%", marginLeft: "5%" }}>
                            <div className="" > <h5><b>Entropi</b></h5><div style={{
                                marginTop: "15px",
                                marginBottom: "15px",
                                border: "solid grey",
                                borderWidth: "0 0 4px 0px"
                            }} /></div>
                            <div>Entropi is designed as mobile friendly application. It is available on all mobile devices with Chrome or Edge browsers.  You do not have to install from any playstore. </div>
                            <p>
                                {/* <div className="mt-5">
                                    <div>

                                        Just use the same link (<a href="https://mob.sceem.org/" className="link-color">https://mob.sceem.org/</a>) and enjoy its rich features.</div>
                                </div> */}
                            </p>

                        </Col>
                        <Col md={1}></Col>
                    </Row></Container>
                    {/* <div className="nebutecH" ><img src={nebutech} className="nebutecH-login-img" /></div> */}
                </div>}
            </div>
        );
    }
}

export default App;


