import React, { useContext, useEffect, useState } from 'react'
import { config } from '../Config/Config';
import SimpleCrypto from 'simple-crypto-js';
import EmployeeContext from '../context/employee/EmpContext';
import axios from 'axios';
import CertificatePopup from '../PopUps/CertificatePopup';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const Reward = ({ }) => {
    const { empState: employee } = useContext(EmployeeContext)
    const [isLoading, setIsLoading] = useState(true);
    const [rewardsLeaderboard, setRewardsLeaderboard] = useState([]);
    const [showCertificate, setShowCertificate] = useState(false);
    const [popupData, setPopupData] = useState(null);

    useEffect(() => {
        getRewardsData();
    }, []);

    const getRewardsData = () => {
        setIsLoading(true);
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }

        let payload = {}
        if (employee.role == 1) payload["companyId"] = employee.companyId;
        else if (employee.role == 2) payload["branchId"] = employee.branchId;
        else if (employee.role == 3) { payload["branchId"] = employee.branchId; payload["employeeId"] = employee.employeeId };
        console.log("payload", payload);
        axios.post(config.apiUrl + 'employees/getRewardsDataByEmployee', payload, { headers: headers })
            .then((response) => {
                console.log(response.data.message, response.data);
                setRewardsLeaderboard(response.data.rewardsData);
                setIsLoading(false);
            }).catch(error => {
                console.log("error", error.response.data.message);
                setIsLoading(false);
            })
    }
    const toggleCertificatePopup = (data = {}) => {
        setPopupData(data);
        setShowCertificate(!showCertificate)
    }
    if (isLoading) {
        return <div className="flex justify-center items-center w-full p-10 text-xl text-center font-semibold">Loading...</div>
    }
    return (
        <div>
            {showCertificate && <CertificatePopup data={popupData} togglePopup={toggleCertificatePopup} />}
            <>
                <div className='flex my-1 w-full justify-between'>
                    <p className='text-xl font-semibold w-4/6 pr-3 text-right'>Leaderboard</p>
                </div>
                {rewardsLeaderboard.length === 0 ? <div className="flex justify-center items-center w-full p-10 text-xl text-center font-semibold">Looking Forward</div> :
                    <div className='over-flow-staff'>
                        {rewardsLeaderboard && rewardsLeaderboard.map((data, i) => (
                            <div key={i} className={"grid grid-cols-6 text-xs todo-card items-center w-full rounded-md border-gray-500 px-1"} onClick={() => toggleCertificatePopup(data)}>
                                <div className="col-span-2 border-0">{data.employeeName}</div>
                                <div className='col-span-2'>{data.tag}</div>
                                <div className='col-span-2 text-right'>{data.monthAndYear}</div>
                            </div>
                        ))}
                    </div>
                }
            </>
        </div>
    )
}

export default Reward;