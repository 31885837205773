import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Button, Row, Col, Card } from "react-bootstrap";
import { config } from '../Config/Config';
import SimpleCrypto from 'simple-crypto-js';
import EmployeeContext from '../context/employee/EmpContext';
import moment from "moment-timezone";


var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const CustomerScreen = ({ customers, oldCustomers, role, section, setSection }) => {
    const navigate = useNavigate();
    const { empState: employee } = useContext(EmployeeContext);
    const [currentCustomers, setCurrentCustomers] = useState([]);

    useEffect(() => {
        console.log("employee", employee);
        setSection("CustomerEvaluation")
        let currentUnattended = customers.filter(cus => cus.activityStatus !== 1)
        let cust = [...currentUnattended, ...oldCustomers]
        cust.sort((a, b) => {
            if (a.activityStatus > b.activityStatus) return 1
            else if (a.activityStatus < b.activityStatus) return 0
            else return new Date(b.updatedAt) - new Date(a.updatedAt);
        })
            setCurrentCustomers([...cust])
        }, [customers]);
        const handleNavigate = (data, section) => {
            setSection(section)
            navigate(`/relationshipmanager/${section}`, { state: { employee, data } }) //changed sudomain route
        }
        return (
            <div className='ml-2 mr-2'>
                {role === 2 ?
                    <div>
                        <center className='mt-1 text-lg font-semibold'>Customers</center>
                        <div className='over-flow'>
                            {customers.length === 0 ? <center>Looking Forward</center> : ""}
                            {customers && customers.map((data, i) => (
                                <div className='mt-2 flex w-full text-sm customers-card border-[1px] justify-around rounded-md border-gray-500 items-center'>
                                    <div className={moment(data.createdAt).format("YYYY-MM-DD HH") === moment(new Date(Date.now())).format("YYYY-MM-DD HH") ? "mt-1 ml-1 w-2 h-2 bg-green-600 rounded-xl" : "mt-1 ml-1 w-2 h-2 bg-red-600 rounded-xl"}></div>
                                    <div className='ml-1 w-[45%] text-left'>{data.firstName ? data.firstName : "-"}&nbsp;{data.lastName ? data.lastName : ""}</div>
                                    <div className="w-[50%] text-left mr-1">{employee.role === 2 ? <>{data.employeeName} <br />({data.employeeId})</> : ""}</div>
                                </div>
                            ))}
                        </div>
                    </div> :
                    <div>
                        <center className='mt-1 text-lg font-semibold'>Customers Evaluation</center>
                        <div className='over-flow-cust-rm'>
                            {currentCustomers.length === 0 && oldCustomers.length === 0 ? <center>Looking Forward</center> : ""}
                            {currentCustomers && currentCustomers.map((data, i) => (
                                <div key={i} onClick={() => handleNavigate(data, "Evaluation")} className={"grid grid-cols-7 text-xs customers-card items-center w-full rounded-md border-gray-500"}>
                                    {/* <div className='w-8'><div className={`ml-2 w-2 h-2 bg-red-600 rounded-xl`}></div></div> */}
                                    <div className={`mt-1 ml-1 w-2 h-2 rounded-xl ${moment(data.createdAt).format("YYYY-MM-DD") === moment(new Date(Date.now())).format("YYYY-MM-DD") && data.activityStatus === 2 ? " bg-green-600" : (data.activityStatus) === 3 ? "bg-blue-400" : " bg-red-600"}`}></div>
                                    <div className="-ml-5 col-span-4 text-xs border-0">{data.firstName ? data.firstName : "-"}&nbsp;{data.lastName ? data.lastName : ""}</div>
                                    <div className='col-span-2 mr-1'>{moment(data.updatedAt).format("DD-MMM")}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                }
            </div >
        )
    }

export default CustomerScreen