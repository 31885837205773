import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from 'simple-crypto-js';
import EmployeeContext from '../context/employee/EmpContext';
import LogoutPopup from '../PopUps/LogoutPopup';
import Customer from '../Customer/Customer';
import Menu from '../Menu/Menu';
import ToDos from '../BranchAdmin/ToDos';
import CustomerDetails from './CustomerDetails';
import CustomerScreen from './CustomerScreen';
import CustomerEvaluation from './CustomerEvaluation';
import moment from 'moment-timezone';
import Events from '../Customer/Events';
import customerdetails from '../images/Customer Details1.svg';
import customerevaluation from '../images/Customer Evaluation1.svg';
import customericon from '../images/CustomerIcon.svg';
import Navbar from '../Navbar/Navbar';
import ForceLogoutPopup from '../PopUps/ForceLogout';
import Deal from '../components/Deal/Deals';
import Reward from './Rewards';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const RelationshipManager = () => {
    const navigate = useNavigate();
    const { part } = useParams();
    const { empState: employee } = useContext(EmployeeContext)
    const [customers, setCustomers] = useState([]);
    const [section, setSection] = useState(part || "Customers");
    const [showPopup, setShowPopup] = useState(false);
    const [totalVisits, setTotalVisits] = useState();
    const [salesCompleted, setSalesCompleted] = useState();
    const [customerLength, setCustomerLength] = useState(0);
    const [customerLengthEval, setCustomerLengthEval] = useState(0);
    const [oldCustomers, setOldCustomers] = useState([]);
    const [detailsCount, setDetailsCount] = useState(0);
    const [evaluationCount, setEvaluationCount] = useState(0);
    const [showForceLogOut, setShowForceLogOut] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [rewardsCount, setRewardCount] = useState(0);
    const isAuthenticated = localStorage.getItem("random") !== null;

    useEffect(() => {
        console.log("isAuth1", isAuthenticated)
        if (!isAuthenticated) {
            console.log("isAuth2", isAuthenticated);
            navigate(-1);
            return;
        }
        console.log("employee", employee);
        getAllCustomers();
        getCustomerVisitsByBranch();
        setSection("Customers");
        const interval = setInterval(() => {
            getAllCustomers();
            getCustomerVisitsByBranch();
        }, 200000);
        return () => clearInterval(interval);

    }, []);
    useEffect(() => {
        let curLength = customers.filter(cus => cus.activityStatus === 1);
        setCustomerLength(curLength.length);
        let curLengthEval = customers.filter(cus => cus.activityStatus === 2);
        setCustomerLengthEval(curLengthEval.length);
        console.log("lenghts", curLength.length, curLengthEval.length);
        custDetailsBackLogCount();
    }, [section, customers]);

    const getCustomerVisitsByBranch = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'employees/getTodaysSalesmanActivities', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, response.data);
                    setSalesCompleted(response.data.totalVisits[0].salesCompleted === null ? 0 : response.data.totalVisits[0].salesCompleted);
                    setTotalVisits(response.data.totalVisits[0]?.totalVisits);
                    setRewardCount(response.data.rewardsCount);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const getAllCustomers = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        setIsLoading(true)
        axios.get(config.apiUrl + 'employees/getTodaysCustomersBySalesmanId', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, response.data);
                    setCustomers(response.data.customers)
                    setOldCustomers(response.data.oldCustomers)
                } else {
                    console.log("Error");
                }
                setIsLoading(false)
            })
            .catch(function (error) {
                console.log("error", error);
                if (error.response.status === 401)
                    setShowForceLogOut(true);
                setIsLoading(false)
            });
    };

    const togglePopup = () => {
        setShowPopup(!showPopup)
    }
    const custDetailsBackLogCount = () => {
        let detailsBacklogCount = 0;
        let evaluationBacklogCount = 0;
        let today = moment().format("DD-MM-YYYY")
        customers.forEach((data) => {
            let updatedAt = moment(data.createdAt).format("DD-MM-YYYY")
            if (data.activityStatus === 1 && today !== updatedAt) detailsBacklogCount++
            if (data.activityStatus === 2 && today !== updatedAt) evaluationBacklogCount++
        }
        )
        setDetailsCount(detailsBacklogCount)
        setEvaluationCount(evaluationBacklogCount)
    }
    if (!isAuthenticated) {
        return <></>
    }

    return (
        <div className=''>
            {showPopup && <LogoutPopup closePopup={togglePopup} employee={employee} />}
            {showForceLogOut && <ForceLogoutPopup employee={employee} />}
            <Navbar />
            <div className='ml-2 mr-2 p-2 border-none bg-slate-100 rounded-b-lg'>
                <div className='flex font-semibold justify-between'>
                    <div className='text-base'>{employee.branchName}</div>
                    <div className='flex text-sm'><img src={customericon} className='w-5 h-5' /><span className='ml-0.5'>{totalVisits}</span><img src={customerdetails} className='ml-1 w-5 h-5 -mt-0.5' /> <span style={{ color: "#ff942c" }}> {detailsCount}</span></div>
                </div>
                <div className='flex font-semibold justify-between'>
                    <div className='text-sm font-semibold'>{employee.firstName} {employee.lastName}<span className='text-xs font-normal'>&nbsp;(RM)</span></div>
                    <div className='flex text-sm'><img src={customerevaluation} className='w-5 h-5 -mt-0.5' /><span className='ml-0.5 -mt-0.5' style={{ color: "#ff942c" }}>{evaluationCount}</span><i className="ml-1.5 fa fa-shopping-cart" aria-hidden="true"></i><span className='ml-1 -mt-0.5'>{salesCompleted}</span></div>
                </div>
            </div>
            {section === "Customers" ?
                isLoading ?
                    <div className="flex justify-center items-center w-full p-10 text-xl text-center font-semibold">Loading...</div>
                    : <div>
                        <Customer customers={customers} oldCustomers={oldCustomers} role={employee.role} section={section} setSection={setSection} />
                        <Events />
                    </div>

                : (section === "CustomerDetails") ?
                    <CustomerDetails setSection={setSection} getAllCustomers={getAllCustomers} />
                    : (section === "CustomerEvaluation") ?
                        <div>
                            <CustomerScreen customers={customers} oldCustomers={oldCustomers} role={employee.role} section={section} setSection={setSection} />
                            <Events />
                        </div>
                        : (section === "Evaluation") ?
                            <CustomerEvaluation section={section} setSection={setSection} getAllCustomers={getAllCustomers} />
                            : (section === "ToDo") ?
                                <ToDos />
                                : (section === "Employees") ?
                                    <Deal />
                                    :
                                    (section === "Rewards") ?
                                        <Reward />
                                        : <Customer customers={customers} role={employee.role} section={section} setSection={setSection} />
            }
            <Menu
                employee={employee}
                section={section}
                setSection={setSection}
                custLength={customerLength}
                custLengthEval={customerLengthEval}
                rewardsCount={rewardsCount}
            />

        </div >
    )
}

export default RelationshipManager