// InstallButton.js
import React, { useState, useEffect } from 'react';
import download from "../images/download.svg";
import logo from "../images/logo.svg"
function InstallButton() {
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [showInstallButton, setShowInstallButton] = useState(false);
    const [isAppInstalled, setIsAppInstalled] = useState(false);

    useEffect(() => {
        const handleBeforeInstallPrompt = (event) => {
            // Prevent the default browser install prompt
            event.preventDefault();
            // Store the event so it can be triggered later
            setDeferredPrompt(event);
            setShowInstallButton(true);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    useEffect(() => {
        // Check if the app is already installed
        if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
            setIsAppInstalled(true);
        }
    }, []);

    useEffect(() => {
        let popupInterval;

        if (!isAppInstalled) {
            // Show the install button every 2 minutes (120,000 milliseconds)
            popupInterval = setInterval(() => {
                setShowInstallButton(true);

                // Set a timer to hide the popup after 30 seconds (30,000 milliseconds)
                setTimeout(() => {
                    setShowInstallButton(false);
                }, 10000);
            }, 20000);

            return () => {
                clearInterval(popupInterval);
            };
        }
    }, [isAppInstalled]);

    const handleInstallClick = () => {
        if (deferredPrompt) {
            // Show the installation prompt
            deferredPrompt.prompt();

            // Wait for the user's choice
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                    setIsAppInstalled(true)
                } else {
                    console.log('User dismissed the install prompt');
                }
            });
        }
    };

    return (
        <div>
            {showInstallButton && (
                <div className="install-popup absolute top-12 left-1/2 -translate-x-1/2 text-black bg-white opacity-95 py-3 px-2 rounded-lg z-[80] border border-gray-400 w-[80%]">
                    <div id="installButton" className='flex justify-between gap-3 items-center'>
                        <div className='flex gap-1 justify-center items-center'>
                            <img src={logo} alt='' className='w-10 h-10' />
                            <span className='whitespace-nowrap'>Install Entropi App</span>
                        </div>
                        {/* <img src={download} alt='' className='w-5 h-5' /> */}
                        <button className='mr-0.5 bg-custom-bg-color text-custom-text-color px-1 py-0.5 rounded' onClick={handleInstallClick}>Install</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default InstallButton;
