import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import SuccessPopup from '../PopUps/SuccessPopup';
import ErrorPopup from '../PopUps/ErrorPopup';
import NebutechLogo from '../NebutechLogo/NebutechLogo';
import logo from "../images/logo.svg";
import picture from "../images/lsm picture.svg";
import NavMenu from './NavMenu';
import { enqueueSnackbar } from 'notistack';
var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


const validPasswordRegex =
    /^.*(?=.{10,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!^*]).*$/

const ResetPassword = () => {
    const [id, setId] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState("")
    const [confirmPasswordError, setConfirmPasswordError] = useState("")
    const [show, setShow] = useState(false);
    const [showError, setShowError] = useState(false);
    const [msg, setMsg] = useState('');
    const [uniqueCode, setUniqueCode] = useState('');
    const [companyDetails, setCompanyDetails] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        const host = window.location.host;
        const arr = host.split("-").slice(0, 2);
        if (!arr[1]) return;
        let code = arr[1].split(".")[0]
        console.log("host", host, arr, code)//changed splitting method for new subdomain
        setUniqueCode(code);
        if (sessionStorage.getItem('cName')) {
            setCompanyDetails(simpleCrypto.decrypt(sessionStorage.getItem('cName')))
        }
    }, [])
    const handleSubmit = (event) => {
        event.preventDefault();
        let formFileds = [
            "password",
            "confirmPassword"
        ];
        let isValid = true;
        formFileds.forEach(field => {
            isValid = validateField(field) && isValid;
        });
        if (!isValid) {
            console.log("Please enter the details")
            enqueueSnackbar('Please enter valid the details!', { variant: 'error' })//chnagd alert 
        } else {
            updatePassword()
        }
    }
    const updatePassword = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        let payload = {
            employeeId: id,
            password: simpleCrypto.encrypt(password),
            uniqueCode: uniqueCode
        }
        axios.post(config.apiUrl + 'employees/resetPassword', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("Password Updated  Successfully!!", response.data)
                    setMsg(response.data.message);
                    setShow(true);
                    setTimeout(() => {
                        setMsg('');
                        setShow(false);
                        navigate("/")
                    }, 2000)

                } else {
                    console.log(response.data.message)
                    setMsg(response.data.message);
                    setShowError(true);
                    setTimeout(() => { setMsg(''); setShowError(false) }, 2000)

                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    }
    const validatePassword = () => {
        let passwordError = "";
        const value = password;
        if (value.trim === "") passwordError = "Password is required";
        else if (!validPasswordRegex.test(value))
            passwordError = "Minimum 10 with 1 capital, 1 numeric and 1 character (!@#$%^&*)";
        setPasswordError(passwordError)
        return passwordError === "";
    }

    const validateConfirmPassword = () => {
        let confirmPasswordError = "";
        if (password !== confirmPassword)
            confirmPasswordError = "Password does not match";
        setConfirmPasswordError(confirmPasswordError);
        return confirmPasswordError === "";
    }
    const validateField = (name) => {
        let isValid = false;
        if (name === "password") isValid = validatePassword();
        else if (name === "confirmPassword")
            isValid = validateConfirmPassword();
        return isValid;
    }
    return (
        <div className="h-screen flex flex-col">
            <SuccessPopup show={show} setShow={setShow} msg={msg} />
            <ErrorPopup show={showError} setShow={setShowError} msg={msg} />
            <NavMenu />
            <section className="my-4 justify-around items-center px-10 max-h-[80vh] overflow-y-scroll">
                <div className="max-w-xl">
                    <img src={picture} />
                </div>
                <div className="max-w-xl min-h-[45vh] max-h-max">
                    <div className="flex flex-col items-center text-center md:text-left mb-2">
                        <p className='text-xl font-semibold'>{companyDetails && companyDetails.name}</p>
                        <img src={companyDetails && companyDetails.logo} alt='' className='w-9 h-9' />
                        <p className="mt-2 font-semibold mr-1 text-lg">Change Password</p>
                    </div>
                    <input className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded" type="text" placeholder="Enter your Employee Id" onChange={(e) => setId(e.target.value)} />
                    <div>
                        <input
                            className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-3"
                            type={showPassword ? "text" : "password"}
                            placeholder="New Password"
                            onChange={(e) => { setPassword(e.target.value); setPasswordError('') }}
                            onBlur={validatePassword}
                        />
                        <span className="sticky float-right -mt-9 p-1 mr-2 cursor-pointer"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <i className="fa fa-eye" aria-hidden="true"></i> : <i className="fa fa-eye-slash" aria-hidden="true"></i>}
                        </span>
                        <div className='error'>{passwordError && (
                            <div className="error">{passwordError}</div>
                        )}</div>
                    </div>
                    <div>
                        <input
                            className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-3"
                            type={showConfirmPassword ? "text" : "password"}
                            placeholder="Confirm Password"
                            onChange={(e) => { setConfirmPassword(e.target.value); setConfirmPasswordError('') }}
                            onBlur={validatePassword}
                        />
                        <span className="sticky float-right -mt-9 p-1 mr-2 cursor-pointer"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        >
                            {showConfirmPassword ? <i className="fa fa-eye" aria-hidden="true"></i> : <i className="fa fa-eye-slash" aria-hidden="true"></i>}
                        </span>
                        <div className='error'>{confirmPasswordError && (
                            <div className="error">{confirmPasswordError}</div>
                        )}</div>
                    </div>
                    <div className="flex items-center text-center md:text-left">
                        <button className="mt-3 h-10 btn-block bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2  font-semibold uppercase rounded text-base" type="submit" onClick={handleSubmit}>Update</button>
                    </div>
                    <div className="mt-3 flex justify-center items-center font-semibold text-sm">
                        <div className="text-slate-500 text-sm">Click here to </div>
                        <Link to={`/`} className="text-sky-500 hover:underline hover:underline-offset-4">&nbsp;Login</Link>
                    </div>
                </div>
            </section>
            <NebutechLogo />
        </div>
    )
}

export default ResetPassword