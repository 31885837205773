import React, { useContext, useEffect, useState } from 'react'
import { config } from '../Config/Config';
import SimpleCrypto from 'simple-crypto-js';
import EmployeeContext from '../context/employee/EmpContext';
import share from '../images/rewards.svg'
import RewardPopup from './RewardPopup';
import axios from 'axios';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const ActiveEmployees = ({ activeEmployees, visitorsCount, isAvailable }) => {
    const { empState: employee } = useContext(EmployeeContext)
    const [employees, setEmployees] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [rewardPopup, setRewardPopup] = useState(false);
    const [rewardData, setRewardData] = useState();
    const [rewardsLeaderboard, setRewardsLeaderboard] = useState([]);
    const [section, setSection] = useState("staff");


    useEffect(() => {
        console.log("activeEmployees", activeEmployees, isAvailable);
        let employees = activeEmployees.filter((data => data.employeeId !== employee?.employeeId))
        setEmployees(employees)
        setIsLoading(false);
        getRewardsData();
    }, [activeEmployees]);

    const getTotalCountForToday = (id) => {
        let count = visitorsCount.filter(vis => vis.salesmanId === id);
        if (!count || count.length === 0) return 0;
        return count[0].totalVisits;
    }
    const toggleRewardPopup = (data = {}) => {
        setRewardData(data);
        setRewardPopup(!rewardPopup);
    };
    const getRewardsData = () => {
        setIsLoading(true);
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }

        let payload = {}
        if (employee.role == 1) payload["companyId"] = employee.companyId;
        else if (employee.role == 2) payload["branchId"] = employee.branchId;
        else if (employee.role == 3) { payload["branchId"] = employee.branchId; payload["employeeId"] = employee.employeeId };
        console.log("payload", payload);
        axios.post(config.apiUrl + 'employees/getRewardsDataByEmployee', payload, { headers: headers })
            .then((response) => {
                console.log(response.data.message, response.data);
                setRewardsLeaderboard(response.data.rewardsData);
                setIsLoading(false);
            }).catch(error => {
                console.log("error", error.response.data.message);
                setIsLoading(false);
            })
    }
    if (isLoading) {
        return <div className="flex justify-center items-center w-full p-10 text-xl text-center font-semibold">Loading...</div>
    }
    return (
        <div>
            {section === "staff" ?
                <>
                    <div className='flex my-1 w-full justify-between'>
                        <p className='text-xl font-semibold w-3/6 text-right'>Staff</p>
                        <button className="self-end mr-2 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-2 py-1 font-semibold uppercase rounded"
                            onClick={() => setSection("leaderboard")}>Leaderboard</button>
                    </div>
                    {rewardPopup && <RewardPopup show={rewardPopup} togglePopup={toggleRewardPopup} data={rewardData} />}
                    {employees.length === 0 ? <div className="flex justify-center items-center w-full p-10 text-xl text-center font-semibold">Looking Forward</div> :
                        <div className='over-flow-staff'>
                            {employees && employees.map((data, i) => (
                                isAvailable ?
                                    <div key={i} className={"grid grid-cols-6 text-xs todo-card items-center w-full rounded-md border-gray-500"}>
                                        <div className="col-span-2 px-1 border-0">{data.firstName}&nbsp;{data.lastName}</div>
                                        <div className='col-span-1 px-2'>{data.employeeId}</div>
                                        <div className='col-span-1 text-center'>{data["weekly_points"] || 0}</div>
                                        <div className='col-span-1 text-center flex justify-between'>
                                            <span>{data["monthly_points"] || 0}</span>
                                            <span>{data["rewardCount"]}</span>
                                        </div>
                                        <div className='col-span-1 ml-auto pr-3' style={employee.role === 2 && parseInt(data.dealStatus) !== 4 && parseInt(data.dealStatus) !== 5 ? { display: "flex" } : { display: "none" }}>
                                            <img src={share} alt='' className={`cursor-pointer py-1`} onClick={() => toggleRewardPopup(data)} />
                                        </div>
                                    </div> :
                                    <div key={i} className={"grid grid-cols-6 text-xs todo-card items-center w-full rounded-md border-gray-500"}>
                                        <div className="col-span-3 text-xs px-1 border-0">{data.firstName}&nbsp;{data.lastName}</div>
                                        <div className='col-span-2 text-xs px-2'>{data.employeeId}</div>
                                        <div className='text-right mr-4'>{getTotalCountForToday(data.id)}</div>
                                    </div>
                            ))}
                        </div>
                    }
                </>
                : <>
                    <div className='flex my-1 w-full justify-between'>
                        <p className='text-xl font-semibold w-4/6 pr-3 text-right'>Leaderboard</p>
                        <button className="self-end mr-2 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-3 py-1 font-semibold uppercase rounded"
                            onClick={() => setSection("staff")}>Staff</button>
                    </div>
                    {rewardsLeaderboard.length === 0 ? <div className="flex justify-center items-center w-full p-10 text-xl text-center font-semibold">Looking Forward</div> :
                        <div className='over-flow-staff'>
                            {rewardsLeaderboard && rewardsLeaderboard.map((data, i) => (
                                <div key={i} className={"grid grid-cols-6 text-xs todo-card items-center w-full rounded-md border-gray-500 px-1"}>
                                    <div className="col-span-2 border-0">{data.employeeName}</div>
                                    <div className='col-span-2'>{data.tag}</div>
                                    <div className='col-span-2 text-right'>{data.monthAndYear}</div>
                                </div>
                            ))}
                        </div>
                    }
                </>}
        </div>
    )
}

export default ActiveEmployees;