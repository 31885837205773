import React, { useContext } from 'react'
import EmployeeContext from '../context/employee/EmpContext';
import logo from '../images/logo.svg'

const Navbar = () => {
    const { empState: employee } = useContext(EmployeeContext)
    return (
        <div className="flex w-full h-16 rounded-b-xl items-center justify-left text-sm bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color sticky top-0">
            <div className='justify-left items-center ml-2 w-12 cursor-pointer'><img src={logo} /></div>
            <div className="w-full text-xl font-semibold text-center transform -translate-x-6">{employee?.companyName}</div>
        </div>
    )
}

export default Navbar