import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Button, Row, Col, Card, Carousel } from "react-bootstrap";
import { config } from '../Config/Config';
import SimpleCrypto from 'simple-crypto-js';
import EmployeeContext from '../context/employee/EmpContext';
import '../Menu/Menu.css'
import ForceLogoutPopup from '../PopUps/ForceLogout';


var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const Events = () => {
    const navigate = useNavigate();
    const { part } = useParams();
    const { empState: employee } = useContext(EmployeeContext)
    const [section, setSection] = useState(part || "Customers");
    const [todaysEvents, setTodaysEvents] = useState([]);
    const [showForceLogOut, setShowForceLogOut] = useState(false);
    const [isLoading, setIsLoading] = useState(true)

    const getTodaysEvents = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        };
        let payload = {
            branchId: employee.branchId
        }
        axios.post(config.apiUrl + 'manager/getTodaysEvents', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, response.data);
                    setTodaysEvents(response.data.todaysEventsArray)
                    setIsLoading(false)
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
                if (error.response.status === 401)
                    setShowForceLogOut(true);
                setIsLoading(false)
            });
    };
    useEffect(() => {
        console.log("employee", employee);
        getTodaysEvents();
    }, []);
    if (isLoading) {
        return <div className="flex justify-center items-center w-full p-8 text-xl text-center font-semibold">Loading...</div>
    }
    return (
        <div className="mt-2 mb-16 ml-2 mr-2">
            {showForceLogOut && <ForceLogoutPopup employee={employee} />}
            <div className='border-0 h-24 rounded-lg bg-indigo-50'>
                {!todaysEvents || todaysEvents.length === 0 ? <center className='py-4'>Regular business day</center> : ""}
                <Carousel variant="dark" controls={false}>
                    {todaysEvents && todaysEvents.map((data, id) => {
                        return (
                            <Carousel.Item variant="dark" interval={10000} key={data.id}>
                                <div className='px-1 py-2'>
                                    <center className='font-semibold text-xs'>{data.event}</center>
                                    {data.toDos.map((item, i) => (
                                        <div key={i} className='mt-0.5 ml-1 text-xs'>{item}<br /></div>
                                    ))}
                                </div>
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
            </div>
        </div>
    )
}

export default Events