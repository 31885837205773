import React, { useState } from 'react';
import upArrow from "../../images/up-arrow.svg";
import downArrow from "../../images/down-arrow.svg";

const MenuItem = ({ title, content, isOpen, toggleOpen }) => {
    return (
        <div className="border-b-2 m-2">
            <div className={`flex justify-between ${isOpen ? 'active' : ''}`} onClick={toggleOpen}>
                <p className='text-sm font-medium '>{title}</p>
                <div className='flex items-center gap-1'>
                    <div className='flex items-center justify-center text-sm w-5 h-5 bg-custom-bg-color text-custom-text-color rounded-full'>{content.props.data.length}</div>
                    <img src={isOpen ? upArrow : downArrow} alt='' className='cursor-pointer' />
                </div>
            </div>
            {isOpen && <div className="menu-content">{content}</div>}
        </div>
    );
};

export default MenuItem;