import React, { useContext, useEffect, useState } from 'react'
import Navbar from '../../Navbar/Navbar'
import EmployeeContext from '../../context/employee/EmpContext'
import axios from 'axios';
import { config } from '../../Config/Config';
import SimpleCrypto from 'simple-crypto-js';
import ForceLogoutPopup from '../../PopUps/ForceLogout';
import moment from 'moment-timezone';
import newcustomer from "../../images/New Customer.svg";
import logout from '../../images/logout.svg';
import { useParams } from 'react-router-dom';
import LogoutPopup from '../../PopUps/LogoutPopup';

var key = config.PASSWORD_ENCRYPTION_KEY;
var simpleCrypto = new SimpleCrypto(key);

export const CompanyAdmin = () => {
    const { empState: employee } = useContext(EmployeeContext);
    const { part } = useParams();
    const [section, setSection] = useState(part || "AddCustomer");
    const [customers, setCustomers] = useState([]);
    const [showForceLogOut, setShowForceLogOut] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        getAllCustomers();
    }, [])
    const getAllCustomers = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getTodaysCustomersByCompany', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, response.data);
                    setCustomers(response.data.newCustomers)
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
                if (error.response.status === 401)
                    setShowForceLogOut(true);
            });
    };
    const togglePopup = () => {
        setShowPopup(!showPopup)
    }
    return (
        <div>
            {showForceLogOut && <ForceLogoutPopup employee={employee} />}
            {showPopup && <LogoutPopup closePopup={togglePopup} employee={employee} />}
            <Navbar />
            <div>
                <div>
                    <div className='ml-2 mr-2 p-2 border-none bg-slate-100 rounded-b-lg'>
                        <div className='flex font-semibold justify-between'>
                            <div className='text-base font-semibold'>{employee.firstName} {employee.lastName}</div>
                            <span className='text-sm font-normal'>Company Admin</span>
                        </div>
                    </div>
                    <center className='mt-1 text-lg font-semibold'>Customers</center>
                    <div className='over-flow-cust-rm'>
                        {customers.length === 0 ? <center>Looking Forward</center> : ""}
                        {customers && customers.map((data, i) => (
                            <div key={i} className={`grid grid-cols-7 text-xs customers-card items-center w-full rounded-md border-gray-500`}>
                                <div className={`col-span-1 ml-3 w-4 rounded-xl`}>{i + 1}</div>
                                <div className="col-span-2 text-xs border-0">{data.customerName}</div>
                                <div className="col-span-2 text-xs border-0">{data.branchName}</div>
                                <div className='col-span-1 ml-3'>{data.employeeId}</div>
                                <div className='col-span-1 ml-auto mr-2'>{moment(data.updatedAt).format("DD-MMM")}</div>
                            </div>
                        ))}
                    </div>
                </div >
            </div>
            <div className='bottom_bar rounded-t-2xl mt-3 sm:text-sm md:text-base text-xs flex justify-around items-center text-center'>
                <div className={`${section === "Customers" ? "bg-gray-500 rounded-xl" : "bg-inherit rounded-xl"} w-10 p-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => { setSection("Customers") }}>
                    <img src={newcustomer} className='w-20' />
                </div>
                <div className={`${section === "Logout" ? "bg-gray-500 rounded-xl" : "bg-inherit rounded-xl"} p-2 w-10 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => togglePopup()}> <img src={logout} alt='' className='w-8 h-6 cursor-pointer' /></div>
            </div>
        </div>
    )
}
