import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import SimpleCrypto from 'simple-crypto-js';
import MenuItem from './MenuItem';
import TableContent from './TableContent';
import { enqueueSnackbar } from 'notistack';
import { config } from '../../Config/Config';
import EmployeeContext from '../../context/employee/EmpContext';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const Deal = ({ activeEmployees, visitorsCount }) => {
    const { empState: employee } = useContext(EmployeeContext)
    const [employees, setEmployees] = useState([])
    const [deals, setDeals] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dealStatus1, setDealStatus1] = useState([]);
    const [dealStatus2, setDealStatus2] = useState([]);
    const [dealStatus3, setDealStatus3] = useState([]);
    const [dealStatus4, setDealStatus4] = useState([]);
    const [openItemIndex, setOpenItemIndex] = useState(null);

    const menuItems = [
        { title: "New", content: < TableContent data={dealStatus1} status={1} /> },
        { title: "Discussion", content: < TableContent data={dealStatus2} status={2} /> },
        { title: "Negotiations", content: < TableContent data={dealStatus3} status={3} /> },
        { title: "Closed", content: < TableContent data={dealStatus4} status={4} /> }
    ];

    useEffect(() => {
        getEmployeeDeals();
    }, []);
    const getEmployeeDeals = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        setLoading(true)
        axios.get(config.apiUrl + 'employees/getDealsByEmployee', { headers: headers })
            .then((response) => {
                if (response.data.status == true) {
                    console.log(response.data.message, response.data)
                    setDeals(response.data.allDeals);
                    let d1 = [], d2 = [], d3 = [], d4 = [];
                    if (response.data.allDeals && response.data.allDeals?.length > 0) {
                        let curDeals = response.data.allDeals;
                        curDeals && curDeals.forEach(element => {
                            let newObj = {
                                customer: element.customer,
                                emp: element.emp,
                                createdAt: element.createdAt,
                                updatedAt: element.updatedAt,
                                dealStatus: element.dealStatus
                            }
                            if (element.dealStatus === 1) d1.push(newObj)
                            else if (element.dealStatus === 2) d2.push(newObj)
                            else if (element.dealStatus === 3) d2.push(newObj)
                            else d4.push(newObj)
                        });
                        setDealStatus1(d1);
                        setDealStatus2(d2);
                        setDealStatus3(d3);
                        setDealStatus4(d4);
                    }
                } else {
                    console.log("Error");
                }
                setLoading(false)
            }).catch((error) => {
                console.error('Error loading products data:', error);
                enqueueSnackbar("Something went wrong!!", { variant: "error" });
                setLoading(false)
            })
    }
    const toggleItem = (index) => {
        if (openItemIndex === index) {
            setOpenItemIndex(null);
        } else {
            setOpenItemIndex(index);
        }
    };

    if (loading) {
        return <div className="flex justify-center items-center w-full p-10 text-xl text-center font-semibold">Loading...</div>
    }
    return (
        <div className='flex flex-col justify-between h-[73vh]'>
            <div className='flex flex-col gap-2 my-4'>
                {menuItems.map((menuItem, index) => (
                    <MenuItem
                        key={index}
                        title={menuItem.title}
                        content={menuItem.content}
                        isOpen={openItemIndex === index}
                        toggleOpen={() => toggleItem(index)}
                    />
                ))}
            </div>
            <div className={`flex items-center w-full px-2 gap-4 text-xs`}>
                <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-green-600 rounded-xl'></div><span>Deal Successful</span></div>
                <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-red-600 rounded-xl'></div><span>Customer Not Interested</span></div>
            </div>
        </div>
    )
}

export default Deal;