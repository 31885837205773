import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Button, Row, Col, Card } from "react-bootstrap";
import { config } from '../Config/Config';
import SimpleCrypto from 'simple-crypto-js';
import EmployeeContext from '../context/employee/EmpContext';
import moment from "moment-timezone";
import { enqueueSnackbar } from 'notistack'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import MultiSelect from '../utils/SearchBox';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const CustomerEvaluation = ({ section, setSection, getAllCustomers }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const custData = location?.state?.data;
    const { empState: employee } = useContext(EmployeeContext)
    const [errors, setErrors] = useState({});
    const [satisfactions, setSatisfactions] = useState([]);
    const [selectedSatisfaction, setSelectedSatisfaction] = useState(5);
    const [potentials, setPotentials] = useState([]);
    const [selectedPotential, setSelectedPotential] = useState(5);
    const [interaction, setInteraction] = useState([]);
    const [activity, setActivity] = useState([]);
    const [selectedInteraction, setSelectedInteraction] = useState(3);
    const [selectedActivity, setSelectedActivity] = useState(2);
    const [customerInterest, setCustomerInterest] = useState('');
    const [actionTypes, setActionTypes] = useState([]);
    const [products, setProducts] = useState([]);
    const [productSelected, setProductSelected] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [oldNotes, setOldNotes] = useState({
        notes: "",
        targetDate: "",
        actionType: ""
    })
    const [newNotes, setNewNotes] = useState({
        notes: "",
        targetDate: "",
        actionType: ""
    });
    const [orgAct, setOrgAct] = useState(2)

    useEffect(() => {
        console.log("employee123", employee, custData);
        setSection("Evaluation")
        if (custData.products) {
            setProductSelected(custData.products);
        }
        if (custData.activityStatus === 3) {
            getCustActivities()
        }
        getAllParameters();
        getBranchProducts();
    }, []);

    const setDefault = (options) => {
        if (!custData.products) return;
        let defaultOpt = options.filter(op => custData.products.some(t => t === op.value))
        setSelectedOptions(defaultOpt);
    }
    const getCustActivities = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "activityId": custData.activityId
        }
        axios.post(config.apiUrl + 'employees/getCustActivities', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    let currentNotes = { ...oldNotes };
                    console.log("1234", response.data.customers);
                    response.data.customers?.userActivity ? setSelectedActivity(response.data.customers?.userActivity) : setSelectedActivity(2)
                    response.data.customers?.userActivity ? setOrgAct(response.data.customers?.userActivity) : setOrgAct(2)
                    response.data.customers?.interaction ? setSelectedInteraction(response.data.customers?.interaction) : setSelectedInteraction(3)
                    response.data.customers?.customersInterest ? setCustomerInterest(response.data.customers?.customersInterest) : setCustomerInterest('')
                    response.data.customers?.customerSatisfaction ? setSelectedSatisfaction(response.data.customers?.customerSatisfaction) : setSelectedSatisfaction(5)
                    response.data.customers?.customerPotential ? setSelectedPotential(response.data.customers?.customerPotential) : setSelectedPotential(5)
                    response.data.activityToDos?.targetDate ? currentNotes = { ...currentNotes, ["targetDate"]: response.data.activityToDos?.targetDate } : currentNotes = { ...currentNotes, ["targetDate"]: null }
                    response.data.activityToDos?.notes ? currentNotes = { ...currentNotes, ["notes"]: response.data.activityToDos?.notes } : currentNotes = { ...currentNotes, ["notes"]: '' }
                    response.data.activityToDos?.actionType ? currentNotes = { ...currentNotes, ["actionType"]: response.data.activityToDos?.actionType } : currentNotes = { ...currentNotes, ["actionType"]: '' }
                    setOldNotes(currentNotes)
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const getAllParameters = () => {
        axios.get(config.apiUrl + 'employees/getAllParameters')
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message);
                    setSatisfactions(response.data.satisfactions);
                    setPotentials(response.data.potentials);
                    var a = response.data.interactions.filter((data, i) => moment(custData?.createdAt).format("MM/DD/YYYY") < moment(new Date()).format("MM/DD/YYYY") && data.id === 1 ? null : data)
                    setInteraction(a);
                    setActionTypes(response.data.actionTypes);
                    let activityArr = response.data.userActivity;
                    let excludeItemsId = [5, 6, 7]
                    activityArr = activityArr.filter(a => !excludeItemsId.includes(a.id))
                    setActivity(activityArr);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const updateProductOptions = (products) => {
        let currentOptions = products.map((item) => {
            let obj = {
                value: item.id,
                label: item.productFamilyName
            }
            return obj;
        })
        console.log("current options", currentOptions)
        setProductOptions(currentOptions)
        setDefault(currentOptions)
    }
    const updateCustomerActivity = () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: 'error' })
            return
        };
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "customerId": custData.customerId,
            "interaction": selectedInteraction ? parseInt(selectedInteraction) : null,
            "userActivity": parseInt(selectedActivity),
            "salesmanId": employee.id,
            "customerSatisfaction": selectedSatisfaction ? parseInt(selectedSatisfaction) : null,
            "customerPotential": selectedPotential ? parseInt(selectedPotential) : null,
            "customersInterest": customerInterest,
            "notes": newNotes.notes ? newNotes.notes : parseInt(newNotes.actionType) === 3 ? "Meet InPerson" : parseInt(newNotes.actionType) === 2 ? "Send Email" : "Make a call",
            "targetDate": newNotes.targetDate,
            "employeeId": employee.employeeId,
            "products": JSON.stringify(productSelected),
            "actionType": newNotes.actionType ? parseInt(newNotes.actionType) : null,
            "activityId": custData.activityId,
            "visitId": custData.visitId,
            "activityStatus": 3,
            "newActivity": orgAct != selectedActivity,
            "branchId": employee.branchId
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'employees/addCustomerEvaluation', payload, { headers: headers })
            .then((response) => {
                if (newNotes.targetDate) {
                    axios.post(config.apiUrl + 'employees/createSalesPersonDashboard', payload, { headers: headers })
                        .then((response) => {
                            console.log(response);
                            window.location.reload(true)
                            enqueueSnackbar('Customer Evaluation Added Successfully!', { variant: 'success' })
                            getAllCustomers();
                        })
                        .catch(function (error) {
                            console.log("error", error, payload);
                        });
                } else {
                    console.log(response);
                    enqueueSnackbar("Customer Evaluation Added Successfully!", { variant: 'success' })
                    window.location.reload(true)
                    getAllCustomers();
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });

    };
    const getBranchProducts = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            branchId: employee.branchId
        }
        axios.post(config.apiUrl + 'employees/getBranchProducts', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success products", response.data);
                    setProducts(response.data.products);
                    updateProductOptions(response.data.products);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const handleChange = (val, arr, func) => {
        func(val);
    }
    const getActionType = (typeId) => {
        let actionType = actionTypes.find(item => item.id === typeId);
        return actionType?.actionType;
    }
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!selectedInteraction) {
            errorObj = {
                ...errorObj,
                interactionError: true
            }
            error = true;
        }
        if (!selectedSatisfaction) {
            errorObj = {
                ...errorObj,
                satisfactionError: true
            }
            error = true;
        }
        if (!selectedPotential) {
            errorObj = {
                ...errorObj,
                potentialError: true
            }
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    }
    const handleOptionChange = (option, action) => {
        console.log(option, action)
        if (action.action === "clear") {
            setProductSelected([]);
            setSelectedOptions([]);
        } else if (action.action === "remove-value") {
            setProductSelected(productSelected.filter(product => product !== option.value))
            setSelectedOptions(option);
        } else {
            setProductSelected(option.map(item => item.value));
            setSelectedOptions(option);
        }
    }
    return (
        <div className='ml-2 mr-2 mb-16'>
            <div>
                <center className='mt-1 text-lg font-semibold'>Customer Evaluation</center>
                <div className='w-full'>
                    <select name="activity" value={selectedActivity} className={`text-xs w-full p-2 ${errors.activityError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-1`}
                        onChange={(e) => { handleChange(e.target.value, activity, setSelectedActivity); setErrors({ ...errors, activityError: false }) }} >
                        <option value="">---User Activity---</option>
                        {activity && activity.map(item => {
                            return (
                                <option value={item.id} key={item.id}>{item.activity}</option>
                            )
                        })}
                    </select>
                    <select name="interaction" value={selectedInteraction} className={`text-xs w-full p-2 ${errors.interactionError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-1`}
                        onChange={(e) => { handleChange(e.target.value, interaction, setSelectedInteraction); setErrors({ ...errors, interactionError: false }) }} >
                        <option value="">---Interaction---</option>
                        {interaction && interaction.map(item => {
                            return (
                                <option value={item.id} key={item.id}>{item.interaction}</option>
                            )
                        })}
                    </select>
                    <select name="satisfaction" value={selectedSatisfaction} className={`text-xs w-full p-2 ${errors.satisfactionError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-1`}
                        onChange={(e) => { handleChange(e.target.value, satisfactions, setSelectedSatisfaction); setErrors({ ...errors, satisfactionError: false }) }} >
                        <option value="">---Cutomer Satisfaction---</option>
                        {satisfactions && satisfactions.map(item => {
                            return (
                                <option value={item.id} key={item.id}>{item.satisfaction}</option>
                            )
                        })}
                    </select>
                    <select name="potential" value={selectedPotential} className={`text-xs w-full p-2 ${errors.potentialError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-1`}
                        onChange={(e) => { handleChange(e.target.value, potentials, setSelectedPotential); setErrors({ ...errors, potentialError: false }) }} >
                        <option value="">----Cutomer Potential----</option>
                        {potentials && potentials.map(item => {
                            return (
                                <option value={item.id} key={item.id}>{item.potential}</option>
                            )
                        })}
                    </select>
                    <div className="w-full grid grid-cols-1 text-xs border rounded-md bg-white mt-1 text-black h-20">
                        <MultiSelect options={productOptions} handleChange={handleOptionChange} placeholder={"--Choose a product--"} value={selectedOptions} />
                    </div>
                    <textarea className="text-xs w-full p-2 border border-solid border-gray-300 rounded mt-1 resize-none"
                        type="text"
                        rows={2}
                        maxLength={120}
                        onChange={(e) => setCustomerInterest(e.target.value)}
                        value={customerInterest}
                        name="customerInterest"
                        placeholder="Customer's Interest"></textarea>

                    <select name="actionType" value={newNotes.actionType ? newNotes.actionType : oldNotes.actionType} className="text-xs w-full p-2 border border-solid border-gray-300 rounded"
                        onChange={(e) => {
                            let newValues = { ...newNotes }
                            newValues["actionType"] = e.target.value;
                            setNewNotes(newValues);
                            let oldValues = { ...oldNotes }
                            oldValues["actionType"] = '';
                            setOldNotes(oldValues)
                        }} >
                        <option value="">---Action Type---</option>
                        {actionTypes && actionTypes.map(item => {
                            return (
                                <option value={item.id} key={item.id}>{item.action}</option>
                            )
                        })}
                    </select>
                    <textarea className="text-xs w-full p-2 border border-solid border-gray-300 rounded mt-1 resize-none"
                        type="text"
                        rows={2}
                        maxLength={120}
                        onChange={(e) => {
                            let newValues = { ...newNotes }
                            newValues["notes"] = e.target.value;
                            setNewNotes(newValues);
                            let oldValues = { ...oldNotes }
                            oldValues["notes"] = '';
                            setOldNotes(oldValues)
                        }}
                        value={newNotes.notes ? newNotes.notes : oldNotes.notes}
                        name="notes"
                        placeholder="Relationship Manager Notes"></textarea>
                    <DatePicker className={`text-xs w-48 p-2 border-[1px] ${errors.targetDateError ? " border-red-600" : "border-gray-300"} cursor-pointer rounded`}
                        isClearable
                        showTimeSelect
                        dateFormat="MMMM d, yyyy h:mmaa"
                        placeholderText="Target Date"
                        filterDate={d => {
                            return new Date() < d;
                        }}
                        selected={newNotes.targetDate ? new Date(newNotes.targetDate) : oldNotes.targetDate ? new Date(oldNotes.targetDate) : null}
                        minTime={new Date().setHours(8, 0, 0)}
                        maxTime={new Date().setHours(19, 0, 0)}
                        onChange={date => {
                            let newValues = { ...newNotes }
                            if (!date) {
                                let oldValues = { ...oldNotes }
                                newValues["targetDate"] = null;
                                oldValues["targetDate"] = null;
                                setNewNotes(newValues)
                                setOldNotes(oldValues)
                                return;
                            }
                            setErrors({ ...errors, targetDateError: false })
                            let new_date = new Date(date);
                            let hours = new_date.getHours();
                            if (hours < 8)
                                new_date = new_date.setHours(8, 0, 0);
                            newValues["targetDate"] = new_date;
                            setNewNotes(newValues)
                        }}
                        onFocus={(e) => {
                            e.target.blur();
                        }}
                    />
                </div>
                <div className="flex justify-end items-center">
                    <button
                        className="mt-2 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color h-10 px-3 py-2 font-semibold uppercase rounded text-sm"
                        type="submit"
                        onClick={updateCustomerActivity}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CustomerEvaluation