import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { useLocation, useNavigate } from "react-router-dom";
import SimpleCrypto from "simple-crypto-js";
import { enqueueSnackbar } from 'notistack';
import ErrorPopup from "../PopUps/ErrorPopup";
import EmployeeContext from "../context/employee/EmpContext";
import MultiSelect from "../utils/SearchBox";
import ForceLogoutPopup from "../PopUps/ForceLogout";
var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


const AddCustomer = ({ getAllCustomers }) => {
    const navigate = useNavigate();
    const locations = useLocation();
    const { empState: employee } = useContext(EmployeeContext)
    // const employee = locations.state?.employee;
    const [employees, setEmployees] = useState([]);
    const [empSelected, setEmpSelected] = useState('');
    const [products, setProducts] = useState([]);
    const [productSelected, setProductSelected] = useState([]);
    const [empSelectedError, setEmpSelectedError] = useState('');
    const [productSelectedError, setProductSelectedError] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [leadType, setLeadType] = useState(1);
    const [showError, setShowError] = useState(false);
    const [msg, setMsg] = useState('');
    const [productOptions, setProductOptions] = useState([]);
    const [errors, setErrors] = useState({});
    const [showForceLogOut, setShowForceLogOut] = useState(false);


    useEffect(() => {
        getAllEmployeesByBranch()
        getAllBranchProducts();
    }, [])
    const getAllBranchProducts = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "branchId": employee?.branchId
        }
        axios.post(config.apiUrl + 'manager/getAllBranchProducts', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log(response.data.message, response.data);
                    setProducts(response.data.products)
                    updateProductOptions(response.data.products);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
                if (error.response.status === 401)
                    setShowForceLogOut(true);
            });
    };
    const updateProductOptions = (products) => {
        let currentOptions = products.map((item) => {
            let obj = {
                value: item.id,
                label: item.productFamilyName
            }
            return obj;
        })
        console.log("current options", currentOptions)
        setProductOptions(currentOptions)
    }

    const handleEmployeeChange = (e) => {
        setEmpSelected(e.target.value);
        setEmpSelectedError('');
    }

    const getAllEmployeesByBranch = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'manager/getAllEmployeesByBranch', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    let cEmployees = response.data.employees.filter(employee => employee.role === 3 && employee.status === 1)
                    console.log("E", cEmployees);
                    setEmployees(cEmployees);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
                if (error.response.status === 401)
                    setShowForceLogOut(true);
            });
    };
    const createCustomer = () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: 'error' })
            return
        };
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "branchName": employee.branchName,
            "branchId": employee.branchId,
            "companyId": employee.companyId,
            "salesmanId": empSelected,
            "products": JSON.stringify(productSelected),
            "firstName": firstName,
            "lastName": lastName,
            "email": email ? simpleCrypto.encrypt(email) : null,
            "mobile": mobile ? simpleCrypto.encrypt(mobile) : null,
            "status": 1,
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'manager/addNewCustomer', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    console.log("success", response.data.message, payload);
                    enqueueSnackbar('Customer Added Successfully!', { variant: 'success' })
                    window.location.reload(true)
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
                setMsg(error.response.data.message);
                setShowError(true);
                setTimeout(() => { setMsg(''); setShowError(false) }, 2000)
            });
    };

    const handleOptionChange = (option, action) => {
        if (action.action === "clear") {
            setProductSelected([]);
        } else if (action.action === "remove-value") {
            setProductSelected(productSelected.filter(product => product !== option.value))
        } else
            setProductSelected(option.map(item => item.value))
    }
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!firstName) {
            errorObj = {
                ...errorObj,
                firstNameError: true
            }
            error = true;
        }
        if (!email) {
            errorObj = {
                ...errorObj,
                emailError: true
            };
            error = true;
        }
        if (!mobile) {
            errorObj = {
                ...errorObj,
                mobileError: true
            };
            error = true;
        }
        if (!productSelected) {
            errorObj = {
                ...errorObj,
                productSelectedError: true
            }
            error = true;
        }
        if (!empSelected) {
            errorObj = {
                ...errorObj,
                empSelectedError: true
            }
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    }
    return (
        <section className="mt-5 ml-2 mr-2">
            <ErrorPopup show={showError} setShow={setShowError} msg={msg} />
            {showForceLogOut && <ForceLogoutPopup employee={employee} />}
            <div className="md:w-1/3 max-w-sm -mt-10">
                <div className="text-center md:text-left">
                    <label className="mt-1 font-semibold mr-1 text-lg text-black-600">New Customer</label>
                </div>
                {/* <input className="text-xs w-full px-4 py-2 border border-solid border-gray-300 rounded mt-1" */}
                <input className={`text-xs w-full px-4 py-2 ${errors.firstNameError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-`}
                    type="text"
                    onChange={(e) => setFirstName(e.target.value)}
                    name="firstName"
                    placeholder="First Name" />
                <input className="text-xs w-full px-4 py-2 border border-solid border-gray-300 rounded mt-1"
                    type="text"
                    onChange={(e) => setLastName(e.target.value)}
                    name="lastName"
                    placeholder="Last Name" />
                <input className={`text-xs w-full px-4 py-2 ${errors.emailError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-1`}
                    type="text"
                    onChange={(e) => setEmail(e.target.value)}
                    name="email"
                    placeholder="Email" />
                <input className={`text-xs w-full px-4 py-2 ${errors.mobileError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-1`}
                    type="text"
                    onChange={(e) => setMobile(e.target.value)}
                    name="mobile"
                    placeholder="Mobile" />
                <div className="w-full grid grid-cols-1 text-xs border rounded-md bg-white mt-1 text-black h-20">
                    <MultiSelect options={productOptions} handleChange={handleOptionChange} placeholder={"--Choose a product--"} />
                </div>
                <div className="text-xs">
                    <select name="employee" value={empSelected} className={`text-xs w-full px-4 py-2 ${errors.empSelectedError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-1`}
                        onChange={(e) => handleEmployeeChange(e)} >
                            <option value="" className="">Select Relationship Manager</option>
                            {employees && employees.map(employee => {
                                return (
                                    <option className="text-xs" key={employee.id} value={employee.id}>{employee.firstName}&nbsp;{employee.lastName}</option>
                                )
                            })}
                    </select>
                </div>
                <div className="flex justify-center text-center md:text-left">
                    <button className="mt-3 btn-block bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color h-10  px-4 py-2 font-semibold  uppercase rounded text-base" onClick={createCustomer} type="submit">Add</button>
                </div>
            </div>
        </section>
    )
}

export default AddCustomer