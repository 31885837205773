import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from 'simple-crypto-js';
import EmployeeContext from '../context/employee/EmpContext';
import moment from "moment-timezone";
import ReassignRm from './ReassignRm';
import CustomerDetails from '../RelationshipManager/CustomerDetails';


var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const Customer = ({ customers, role, activeEmployees, oldCustomers, section, setSection }) => {
    const navigate = useNavigate();
    const { empState: employee } = useContext(EmployeeContext)
    const [currentCustomers, setCurrentCustomers] = useState([]);
    const [reAssign, setReAssign] = useState(false)
    const [data, setData] = useState()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        console.log("customers", customers);
        setSection("Customers")
        let currentUnattended = customers.filter(cus => cus.activityStatus === 1)
        setCurrentCustomers([...currentUnattended])
        setIsLoading(false)
    }, [customers]);

    const handleNavigate = (data, section) => {
        if (!data.visitId) return;
        if (data.visitId && data.status === 2)
            navigate(`/relationshipmanager/${section}`, { state: { employee, data } })
        setSection(section)
        navigate(`/relationshipmanager/${section}`, { state: { employee, data } })
    }
    const reAssignRm = (data) => {
        setReAssign(!reAssign)
        setData(data)
        console.log("o", data)
    }
    const togglePopup = () => {
        setReAssign(!reAssign)
    }
    if (isLoading) {
        return <div className="flex justify-center items-center w-full p-10 text-xl text-center font-semibold">Loading...</div>
    }
    return (
        <div className='ml-2 mr-2'>
            {reAssign && <ReassignRm data={data} closePopup={togglePopup} activeEmployees={activeEmployees} />}
            {role === 2 ?
                <div className='flex flex-col justify-between h-[73vh] overflow-y-auto'>
                    <>
                        <center className='mt-1 text-lg font-semibold'>Customers</center>
                        <div className='over-flow'>
                            {customers.length === 0 ? <center>Looking Forward</center> : ""}
                            {customers && customers.map((data, i) => (
                                <div key={i} className='mt-2 flex w-full text-xs customers-card border-[1px] justify-around rounded-md border-gray-500 items-center'>
                                    <div className={`mt-1 ml-1 w-2 h-2 rounded-xl ${moment(data.createdAt).format("YYYY-MM-DD") === moment(new Date(Date.now())).format("YYYY-MM-DD") && data.activityStatus === 1 ? "bg-green-600" : data.activityStatus === 2 ? "bg-orange-400" : "bg-red-600"}`}></div>
                                    <div className='ml-1 w-[45%] text-xs text-left'>{data.firstName ? data.firstName : "-"}&nbsp;{data.lastName ? data.lastName : ""}</div>
                                    <div className="w-[50%] text-xs text-left mr-1">{employee.role === 2 ? <>{data.employeeName} <br />({data.employeeId})</> : ""}</div>
                                    <div className='mr-2' onClick={() => reAssignRm(data)}><i className="fa fa-share" aria-hidden="true"></i></div>
                                </div>
                            ))}
                        </div>
                    </>
                    <div className={`flex items-center w-full px-2 gap-4 text-xs`}>
                        <div className='flex gap-1 items-center'><div className='w-3 h-3 bg-green-600 rounded-xl'></div><span>New Visit</span></div>
                        <div className='flex gap-1 items-center'><div className='w-3 h-3 bg-orange-400 rounded-xl'></div><span>Pending Evaluation</span></div>
                        <div className='flex gap-1 items-center'><div className='w-3 h-3 bg-red-600 rounded-xl'></div><span>Pending</span></div>
                    </div>
                </div> :
                <div>
                    <center className='mt-1 text-lg font-semibold'>Customers</center>
                    <div className='over-flow-cust-rm'>
                        {currentCustomers.length === 0 && oldCustomers.length === 0 ? <center>Looking Forward</center> : ""}
                        {currentCustomers && currentCustomers.map((data, i) => (
                            <div key={i} onClick={() => handleNavigate(data, "CustomerDetails")} className={`grid grid-cols-7 text-xs customers-card items-center w-full rounded-md border-gray-500`}>
                                <div className={`mt-1 ml-1 w-2 h-2 rounded-xl ${moment(data.createdAt).format("YYYY-MM-DD") === moment(new Date(Date.now())).format("YYYY-MM-DD") && data.activityStatus === 1 ? " bg-green-600" : (data.activityStatus) === 3 ? "bg-blue-400" : " bg-red-600"}`}></div>
                                <div className="-ml-5 col-span-4 text-xs border-0">{data.firstName ? data.firstName : "-"}&nbsp;{data.lastName ? data.lastName : ""}</div>
                                <div className='col-span-2 mr-1'>{moment(data.updatedAt).format("DD-MMM")}</div>
                            </div>
                        ))}
                        {oldCustomers && oldCustomers.map((data, i) => (
                            <div key={i} onClick={() => handleNavigate(data, "CustomerDetails")} className={`grid grid-cols-7 text-xs customers-card items-center w-full rounded-md border-gray-500`}>
                                {/* < div className='w-8' > <div className={`ml-2 w-2 h-2 ${data.visitId && data.status === 1 ? "bg-red-600" : "bg-green-600"} rounded-xl`}></div></div> */}
                                <div className={`mt-1 ml-1 w-2 h-2 rounded-xl ${moment(data.createdAt).format("YYYY-MM-DD") === moment(new Date(Date.now())).format("YYYY-MM-DD") && data.activityStatus === 1 ? " bg-green-600" : (data.activityStatus) === 3 ? "bg-blue-400" : " bg-red-600"}`}></div>
                                <div className="-ml-5 col-span-4 text-xs border-0">{data.firstName ? data.firstName : "-"}&nbsp;{data.lastName ? data.lastName : ""}</div>
                                <div className='col-span-2 mr-1'>{moment(data.updatedAt).format("DD-MMM")}</div>
                            </div>
                        ))}
                    </div>
                </div >
            }
        </div >
    )
}

export default Customer