import React, { useState, useEffect } from "react";
import { Modal } from 'react-bootstrap'
import { useNavigate } from "react-router";
import SimpleCrypto from "simple-crypto-js";
import { config } from "../Config/Config";
import axios from "axios";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const ForceLogoutPopup = ({ employee }) => {
    const navigate = useNavigate();
    useEffect(() => {
        console.log(employee)
    }, []);
    const Logout = () => {
        updateLogOutTime();
        localStorage.clear();
        navigate("/");
    }
    const updateLogOutTime = async () => {
        let headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let payload = {
            employeeId: employee?.employeeId,
            companyId: employee.companyId,
            token: simpleCrypto.decrypt(localStorage.getItem("random")),
            forcefulLogOut: true
        }
        axios.post(config.apiUrl + "employees/updateLogoutTime", payload, { headers: headers })
            .then((response) => {
                if (response.data.status === true) {
                    console.log("Success", response.data.message)
                } else {
                    console.log("Error")
                }
            })
    }
    return (
        <Modal show={true} size="sm" centered contentClassName='rounded-2xl'>
            <Modal.Header closeButton style={{ zIndex: "100" }}>
            </Modal.Header>
            <Modal.Body>
                <div className='flex flex-col gap-4 justify-center items-center'>
                    <div className='-mt-10 text-orange-400 text-center'>
                        <p className="text-lg">You have been looged out</p>
                        <p className="text-sm">Kindly Login again</p>
                    </div>
                    <div className="flex justify-center items-center">
                        <button className="mt-4 bg-custom-bg-color hover:custom-bg-color text-custom-text-color px-4 py-2  font-semibold uppercase rounded text-xs" onClick={Logout} type="submit">Ok</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ForceLogoutPopup

