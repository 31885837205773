import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Button, Row, Col, Card, Carousel } from "react-bootstrap";
import { config } from '../Config/Config';
import SimpleCrypto from 'simple-crypto-js';
import EmployeeContext from '../context/employee/EmpContext';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const TopThreeCustomers = ({ visitorsCount }) => {
    const navigate = useNavigate();
    const { part } = useParams();
    const { empState: employee } = useContext(EmployeeContext)
    const [customers, setCustomers] = useState([
        {
            name: "abc", count: 4
        },
        {
            name: "pqr", count: 2
        },
        {
            name: "xyz", count: 1
        },
    ]);
    const [section, setSection] = useState(part || "Customers");
    useEffect(() => {
        console.log("employee", employee, visitorsCount);
    }, []);
    return (
        <div className='ml-2 mt-2 mr-2 bg-zinc-100 rounded-lg'>
            {visitorsCount.length === 0 ?
                <div className='p-0.5 mt-1 ml-1 mr-1 h-20'>
                    <div className={"mt-1.5 flex justify-center items-center w-full h-16 border-[1px] rounded-md border-gray-300"}>
                        <span>Looking Forward</span>
                    </div>
                </div>
                : <div className="p-0.5 mt-1 ml-1 mr-1 h-20">
                    {visitorsCount && visitorsCount.slice(0, 3).map((data, i) => (
                        <div key={i} className='mt-1'>   <div className={"mt-1 grid grid-cols-6 items-center w-full h-5 border-[1px] rounded-sm border-gray-300"}>
                            <div className="col-span-5 text-xs px-1 border-0">{data.employeeName}</div>
                            <div className='text-xs px-2 border-[1px] border-top-0 border-r-0 border-bottom-0 border-gray-300'>{data.totalVisits}
                            </div>
                        </div>
                        </div>
                    ))}

                </div>
            }
        </div >
    )
}

export default TopThreeCustomers