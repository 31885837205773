import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Button, Row, Col, Card, Table } from "react-bootstrap";
import { config } from '../Config/Config';
import SimpleCrypto from 'simple-crypto-js';
import EmployeeContext from '../context/employee/EmpContext';
import LogoutPopup from '../PopUps/LogoutPopup';
import logout from '../images/logout.svg';
import logo from '../images/logo.svg'
import moment from "moment-timezone";
import TodoPopup from './TodoPopup';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const ToDos = () => {
    const { empState: employee } = useContext(EmployeeContext)
    const [toDoList, setToDoList] = useState([]);
    const [popup, setPopup] = useState(false);
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getTodaysToDos();
    }, []);
    const getTodaysToDos = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "branchId": employee.branchId
        }
        axios.post(config.apiUrl + 'manager/getTodaysToDos', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, response.data);
                    if (employee?.role === 3) {
                        let data = response.data.toDos.filter((data => data.employeeId === employee?.employeeId))
                        setToDoList(data)
                    } else {
                        setToDoList(response.data.toDos)
                    }
                } else {
                    console.log("Error");
                }
                setIsLoading(false)
            })
            .catch(function (error) {
                console.log("error", error);
                setIsLoading(false)
            });
    };
    const openPopup = (data) => {
        setPopup(!popup)
        setData(data)
        console.log("o", data)
    }
    const togglePopup = () => {
        setPopup(!popup)
    }
    if (isLoading) {
        return <div className="flex justify-center items-center w-full p-10 text-xl text-center font-semibold">Loading...</div>
    }
    return (
        <div>
            <div className='ml-1 mr-1 rounded-lg'>
                {popup && <TodoPopup data={data} closePopup={togglePopup} getTodaysToDos={getTodaysToDos} />}
                <center className='mt-1 ml-1 text-xl font-semibold'>To Do</center>
                {toDoList.length === 0 ? <div className='p-0.5 mt-1 ml-1 mr-1'><div className={"mt-1.5 flex justify-center items-center w-full border-00 rounded-md"}>No items pending</div></div> :
                    <div className="p-0.5 over-flow-emp mt-1 mr-1">
                        {toDoList.map((data, i) => (
                            employee.role === 2 ?
                                <div onClick={() => openPopup(data)} key={i} className={`grid grid-cols-9 text-xs todo-card items-center w-full rounded-md ${data.employeeStatus === 2 ? "border-[1px] border-red-600" : " border-gray-500"}`}>
                                    <div className={`flex items-center justify-center ml-1 w-5 h-5 rounded-2xl ${data.status === 1 && data.reachOutId ? "bg-reachout-l-green" : data.status === 1 && data.id ? "bg-gray-400" : (data.status === 3 || data.status === 4) && data.id ? "bg-black" : "bg-reachout-d-green"}`}>{data.actionType === 1 ? <><i className="fa fa-phone color-white" aria-hidden="true"></i></> : (data.actionType) === 2 ? <><i className="fa fa-envelope color-white" aria-hidden="true"></i></> : <><i className="fa fa-user color-white" aria-hidden="true"></i></>}</div>
                                    <div className='-ml-3 col-span-3 mr-1'>{data.employeeName}</div>
                                    <div className="ml-1 col-span-3 text-xs px-1 border-0">{data.firstName ? data.firstName : "-"}&nbsp;{data.lastName ? data.lastName : ""}</div>
                                    <div className='col-span-2 mr-1 ml-4 flex gap-3 items-center'>
                                        {data?.targetDate ? new Date(data.targetDate).getHours() : ""}:{data?.targetDate ? new Date(data.targetDate).getMinutes() < 10 ? "0" + new Date(data.targetDate).getMinutes() : new Date(data.targetDate).getMinutes() : ""}
                                        <i className={`fa fa-share ${data.employeeStatus === 1 || data.status !== 1  ? "hidden" : ""} `} aria-hidden="true" onClick={() => openPopup(data)}></i>
                                    </div>
                                </div>
                                :
                                <div onClick={() => openPopup(data)} key={i} className={"grid grid-cols-8 text-xs todo-card items-center w-full rounded-md border-gray-500"}>
                                    <div className={`flex items-center justify-center ml-1 w-5 h-5 rounded-2xl ${data.status === 1 && data.reachOutId ? "bg-reachout-l-green" : data.status === 1 && data.id ? "bg-gray-400" : (data.status === 3 || data.status === 4) && data.id ? "bg-black" : "bg-reachout-d-green"}`}>{data.actionType === 1 ? <><i className="fa fa-phone color-white" aria-hidden="true"></i></> : (data.actionType) === 2 ? <><i className="fa fa-envelope color-white" aria-hidden="true"></i></> : <><i className="fa fa-user color-white" aria-hidden="true"></i></>}</div>
                                    <div className="-ml-3 col-span-3 text-xs px-1 border-0">{data.firstName ? data.firstName : "-"}&nbsp;{data.lastName ? data.lastName : ""}</div>
                                    {data.notes.length > 35 ?
                                        <div className='-ml-3 col-span-3 mr-1'>{data.notes.substring(0, 35)}...</div> :
                                        <div className='-ml-3 col-span-3 mr-1'>{data.notes}</div>
                                    }
                                    <div className='col-span-1 mr-1'>{data?.targetDate ? new Date(data.targetDate).getHours() : ""}:{data?.targetDate ? new Date(data.targetDate).getMinutes() < 10 ? "0" + new Date(data.targetDate).getMinutes() : new Date(data.targetDate).getMinutes() : ""}</div>
                                </div>
                        ))}
                    </div>
                }
            </div >
        </div>
    )
}

export default ToDos;