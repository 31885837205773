import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from 'simple-crypto-js';
import EmployeeContext from '../context/employee/EmpContext';
import LogoutPopup from '../PopUps/LogoutPopup';
import logout from '../images/logout.svg';
import logo from '../images/logo.svg'
import Customer from '../Customer/Customer';
import AddCustomer from '../Customer/AddCustomer';
import Menu from '../Menu/Menu';
import moment from "moment-timezone";
import ActiveEmployees from './ActiveEmployees';
import TopThreeCustomers from '../Customer/TopThreeCustomers';
import Events from '../Customer/Events';
import ToDos from './ToDos';
import customerdetails from '../images/Customer Details1.svg';
import customerevaluation from '../images/Customer Evaluation1.svg';
import customericon from '../images/CustomerIcon.svg';
import Navbar from '../Navbar/Navbar';
import ForceLogoutPopup from '../PopUps/ForceLogout';
import Deal from '../components/Deal/Deals';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const BranchAdmin = () => {
    const navigate = useNavigate();
    const { part } = useParams();
    const { empState: employee } = useContext(EmployeeContext)
    const [employees, setEmployees] = useState([]);
    const [activeEmployees, setActiveEmployees] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [section, setSection] = useState(part || "AddCustomer");
    const [showPopup, setShowPopup] = useState(false);
    const [toDoList, setToDoList] = useState([]);
    const [visitorsCount, setVisitorsCount] = useState([]);
    const [totalVisits, setTotalVisits] = useState();
    const [salesCompleted, setSalesCompleted] = useState();
    const [backlogCustomers, setBacklogCustomers] = useState(0);
    const [detailsCount, setDetailsCount] = useState(0);
    const [evaluationCount, setEvaluationCount] = useState(0);
    const [showForceLogOut, setShowForceLogOut] = useState(false);
    const isAuthenticated = localStorage.getItem("random") !== null;
    const [isAvailable, setIsAvailable] = useState(true);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate(-1);
            return;
        }
        console.log("employee", employee);
        getAllCustomers();
        getAllEmployeesByBranch()
        getCustomerVisitsByBranch()
        console.log("vist", salesCompleted);
        const interval = setInterval(() => {
            getAllCustomers();
            getCustomerVisitsByBranch()
        }, 200000);
        return () => clearInterval(interval);
    }, []);
    useEffect(() => {
        custDetailsBackLogCount();
    }, [section, customers]);
    const getAllEmployeesByBranch = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'manager/getAllEmployeesByBranch', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, response.data);
                    setEmployees(response.data.employees);
                    let curActiveEmps = response.data.employees.filter(emp => emp.status === 1)
                    setActiveEmployees(curActiveEmps);
                    setIsAvailable(response.data.isAvailable)
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
                if (error.response.status === 401)
                    setShowForceLogOut(true);
            });
    };
    const getAllCustomers = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'manager/getNewCustomersByBranchId', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, response.data);
                    setCustomers(response.data.newCustomers)
                    setBacklogCustomers(response.data.oldCustomersCount)
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
                if (error.response.status === 401)
                    setShowForceLogOut(true);
            });
    };

    const togglePopup = () => {
        setShowPopup(!showPopup)
    }
    const getCustomerVisitsByBranch = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "branchId": employee?.branchId
        }
        axios.post(config.apiUrl + 'manager/getCustomerVisitsByBranch', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, response.data);
                    setVisitorsCount(response.data.visitorsCount);
                    setSalesCompleted(response.data.totalVisits[0].salesCompleted === null ? 0 : response.data.totalVisits[0].salesCompleted);
                    setTotalVisits(response.data.totalVisits[0]?.totalVisits);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
                if (error.response.status === 401)
                    setShowForceLogOut(true);
            });
    };
    const custDetailsBackLogCount = () => {
        let detailsBacklogCount = 0;
        let evaluationBacklogCount = 0;
        let today = moment().format("DD-MM-YYYY")
        customers.forEach((data) => {
            let updatedAt = moment(data.createdAt).format("DD-MM-YYYY")
            if (data.activityStatus === 1 && today !== updatedAt) detailsBacklogCount++
            if (data.activityStatus === 2 && today !== updatedAt) evaluationBacklogCount++
        }
        )
        setDetailsCount(detailsBacklogCount)
        setEvaluationCount(evaluationBacklogCount)
    }

    if (!isAuthenticated) {
        return <></>
    }

    return (
        <div className=''>
            {showPopup && <LogoutPopup closePopup={togglePopup} employee={employee} />}
            {showForceLogOut && <ForceLogoutPopup employee={employee} />}
            <Navbar />
            <div className='ml-2 mr-2 p-2 border-none bg-slate-100 rounded-b-lg'>
                <div className='flex font-semibold justify-between'>
                    <div className='text-base'>{employee.branchName}</div>
                    <div className='flex text-sm'><img src={customericon} className='w-5 h-5' /><span className='ml-0.5'>{totalVisits}</span><img src={customerdetails} className='ml-1 w-5 h-5 -mt-0.5' /> <span style={{ color: "#ff942c" }}> {detailsCount}</span></div>
                </div>
                <div className='flex font-semibold justify-between'>
                    <div className='text-sm font-semibold'>{employee.firstName} {employee.lastName}<span className='text-xs font-normal'>&nbsp;(Branch Admin)</span></div>
                    <div className='flex text-sm'><img src={customerevaluation} className='w-5 h-5 -mt-0.5' /><span className='ml-0.5 -mt-0.5' style={{ color: "#ff942c" }}>{evaluationCount}</span><i className="ml-1.5 fa fa-shopping-cart" aria-hidden="true"></i><span className='ml-1 -mt-0.5'>{salesCompleted}</span></div>
                </div>
            </div>
            {section === "AddCustomer" ?
                <div className='flex flex-col justify-between min-h-max'>
                    <TopThreeCustomers visitorsCount={visitorsCount} />
                    <AddCustomer getAllCustomers={getAllCustomers} />
                    <Events />
                </div>
                : (section === "Employees") ?
                    <ActiveEmployees activeEmployees={activeEmployees} visitorsCount={visitorsCount} isAvailable={isAvailable} />
                    : (section === "Deals") ?
                        <Deal />
                        : (section === "ToDo") ?
                            <ToDos />
                            :
                            <Customer customers={customers} role={employee.role} activeEmployees={activeEmployees} section={section} setSection={setSection} />

            }
            <Menu employee={employee} section={section} setSection={setSection} custLength={customers.length} />
        </div >
    )
}

export default BranchAdmin