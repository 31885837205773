import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react'
import { config } from '../Config/Config';
import { Link, useNavigate, useParams } from 'react-router-dom';
import SimpleCrypto from "simple-crypto-js";
import ErrorPopup from '../PopUps/ErrorPopup';
import picture from "../images/lsm picture.svg";
import NebutechLogo from '../NebutechLogo/NebutechLogo';
import EmployeeContext from '../context/employee/EmpContext';
import LogoutSecond from '../PopUps/LogOut2';
import NavMenu from './NavMenu';
import updateNotificationEndpoint from '../utils/UpdateNotificationEndpoint';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const validPasswordRegex =
    /^.*(?=.{10,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!^*]).*$/

const Login = () => {
    const emp = useContext(EmployeeContext);
    const { companyname, id: uid } = useParams();
    const navigate = useNavigate();
    const [id, setId] = useState('');
    const [idError, setIdError] = useState("")
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState('');
    const [passwordError, setPasswordError] = useState("")
    const [showLogOut, setShowLogOut] = useState(false);
    const [showError, setShowError] = useState(false);
    const [msg, setMsg] = useState('');
    const [companyDetails, setCompanyDetails] = useState();
    const [uniqueCode, setUniqueCode] = useState('');
    const [version, setVersion] = useState()

    useEffect(() => {
        if (localStorage.getItem("random")) {
            let currentEmp = simpleCrypto.decrypt(localStorage.getItem("junk"));
            emp.updateEmpState(currentEmp)
            if (localStorage.getItem("roleId") == 2) {
                navigate(`/branchadmin/AddCustomer`, { state: currentEmp })
            } else {
                navigate(`/relationshipmanager/Customers`, { state: currentEmp })
            }
        }
        const host = window.location.host;
        const arr = host.split("-").slice(0, 2);
        if (!arr[1]) return;
        let code = arr[1].split(".")[0]
        console.log("host", host, arr, code)//changed splitting method for new subdomain
        setUniqueCode(code);
        if (sessionStorage.getItem('cName')) {
            setCompanyDetails(simpleCrypto.decrypt(sessionStorage.getItem('cName')))
        }
    }, [])

    useEffect(() => {
        if (uniqueCode && !companyDetails)
            getCompanyDetails()
    }, [uniqueCode])
    const handleLogin = (e) => {
        e.preventDefault();
        let formFileds = [
            "id",
            "password"
        ];
        let isValid = true;
        formFileds.forEach(field => {
            isValid = validateField(field) && isValid;
        });
        if (!isValid) {
            console.log("Please enter the valid details")
            setMsg("Please enter the valid details");
            setShowError(true);
            setTimeout(() => { setMsg(''); setShowError(false) }, 2000)
        } else
            login();
    }
    const validateField = (name) => {
        let isValid = false;
        if (name === "id") isValid = validateId();
        else if (name === "password") isValid = validatePassword();
        return isValid;
    }
    const validateId = () => {
        let idError = "";
        const value = id;
        if (value.trim === "") idError = "Please enter valid Employee ID";
        setIdError(idError)
        return idError === "";
    }
    const validatePassword = () => {
        let passwordError = "";
        const value = password;
        if (value.trim === "") passwordError = "Password is required";
        else if (!validPasswordRegex.test(value))
            passwordError = "Min 10 with 1 capital, 1 numeric and 1 character (!@#$%^&*)";
        setPasswordError(passwordError)
        return passwordError === "";
    }
    const login = async () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        let payload = {
            employeeId: id,
            companyId: companyDetails ? companyDetails.id : null,
            password: simpleCrypto.encrypt(password),
            source: 2
        }
        axios.post(config.apiUrl + 'employees/login', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    console.log("employee", response.data.employee);
                    if (companyDetails?.id) {
                        response.data.employee["uniqueCode"] = uniqueCode;
                        response.data.employee["companyName"] = companyDetails.name;
                        response.data.employee["logo"] = companyDetails.logo;
                    }
                    localStorage.setItem("random", simpleCrypto.encrypt(response.data.accessToken))
                    localStorage.setItem("roleId", response.data.employee.role)
                    localStorage.setItem("junk", simpleCrypto.encrypt(response.data.employee))
                    emp.updateEmpState(response.data.employee)
                    if (response.data.employee.role == 2) {
                        navigate(`/branchadmin/AddCustomer`, { state: response.data.employee })
                    } else if (localStorage.getItem("roleId") == 3) {
                        navigate(`/relationshipmanager/Customers`, { state: response.data.employee })
                    } else if (response.data.employee.role == 1) {
                        navigate(`/admin/Customers`)
                    } else {
                        setMsg("Only Branch Admin & Relationship Manager Can Login");
                        setShowError(true);
                        setTimeout(() => { setMsg(''); setShowError(false) }, 2000)
                    }
                    updateNotificationEndpoint(response.data.employee.id)
                } else {
                    console.log(response.data.message)
                    setMsg(response.data.message);
                    setShowError(true);
                    setTimeout(() => { setMsg(''); setShowError(false) }, 2000)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
                if (error.response.status === 403) {
                    console.log(error.response.status)
                    setShowLogOut(true);
                }
            });
    }
    const getCompanyDetails = async () => {
        let payload = {
            companyName: companyname,
            uniqueCode: uniqueCode
        }
        axios.post(config.apiUrl + 'employees/getCompanyDetailsByUniqueCode', payload)
            .then(function (response) {
                if (response.data?.details?.length > 0) {
                    setCompanyDetails(response.data.details[0]);
                    sessionStorage.setItem("cName", simpleCrypto.encrypt(response.data.details[0]));
                    setVersion(response.data.versionDetails);
                } else {
                    console.log(response.data.message)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    }

    return (
        <div className="h-screen flex flex-col">
            <NavMenu />
            <section className="my-4 justify-around items-center px-10">
                {showError && <ErrorPopup show={showError} setShow={setShowError} msg={msg} />}
                {showLogOut && <LogoutSecond employeeId={id} companyId={companyDetails?.id} setShowLogOut={setShowLogOut} />}
                <div className="max-w-xl">
                    <img src={picture} />
                </div>
                <form onSubmit={handleLogin} className="max-w-xl h-[45vh]">
                    <div className="flex flex-col items-center text-center md:text-left mb-2">
                        <p className='text-xl font-semibold'>{companyDetails?.name}</p>
                        <img src={companyDetails && companyDetails.logo} alt='' className='w-9 h-9' />
                        <p className="font-semibold mt-2 mr-1 text-lg">Sign in</p>
                    </div>
                    <div>
                        <input
                            className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded"
                            type="text"
                            placeholder="Enter Your Employee Id"
                            onChange={(e) => { setId(e.target.value); setIdError('') }}
                            onBlur={validateId}
                        />
                        <div className='error'>{idError && (
                            <div className="error">{idError}</div>
                        )}</div>
                    </div>
                    <div>
                        <input
                            className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-3"
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter your Password"
                            onChange={(e) => { setPassword(e.target.value); setPasswordError('') }}
                            onBlur={validatePassword}
                        />
                        <span className="sticky float-right -mt-9 p-1 mr-2 cursor-pointer"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <i className="fa fa-eye" aria-hidden="true"></i> : <i className="fa fa-eye-slash" aria-hidden="true"></i>}
                        </span>
                        <div className='error'>{passwordError && (
                            <div className="error">{passwordError}</div>
                        )}</div>
                    </div>
                    <div className="items-center text-center md:text-left">
                        <button
                            className="mt-3 btn-block h-10 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2  font-semibold uppercase rounded text-base"
                            type="submit"
                            onKeyDown={(e) => console.log(e)}
                        >
                            Login
                        </button>
                        <div className='mt-2 font-semibold'>
                            <Link to={`/resetpassword`} className="text-sm text-red-600 hover:text-red-700 hover:underline hover:underline-offset-4">Forgot Password</Link>
                        </div>
                    </div>
                    <div className="mt-3 items-center font-semibold text-sm">
                        <div className="font-semibold text-sm text-black text-center md:text-left">
                            New account?&nbsp;
                            <Link to={`/signup`} className="text-sky-500 hover:underline hover:underline-offset-4" href={`/${companyname}/${uid}/signup`}>&nbsp;Register here</Link>
                        </div>

                    </div>
                </form>
            </section>
            <div>
                <div className="absolute bottom-2 left-2 text-xs">{version && version.version}</div>
                <NebutechLogo />
            </div>
        </div>

    )
}

export default Login