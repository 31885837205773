import React, { useContext } from 'react';
import EmployeeContext from '../../context/employee/EmpContext';
import moment from 'moment-timezone';
import { Spinner } from 'react-bootstrap';

const TableContent = ({ data, status }) => {
    const { empState: employee } = useContext(EmployeeContext);

    const daysDiff = (date1, date2) => {
        const curStatus = parseInt(status);
        const oneDay = 24 * 60 * 60 * 1000;
        const firstDate = curStatus === 4 || curStatus === 5 ? new Date(date1) : new Date();
        const secondDate = new Date(date2);
        const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
        return diffDays + 1;
    }
    const noData = () => {
        return (
            // <tr className="whitespace-nowrap">
            //     <td className="td-text"></td>
            //     <td className="td-text font-semibold text-md text-right pl-12">No Data</td>
            //     <td className="td-text"></td>
            //     <td className="td-text"></td>
            // </tr>
            <div className='flex justify-center items-center mt-3 font-semibold text-md'>
                No Data
            </div>
        )
    }
    return (
        <div className='max-h-[40vh] overflow-y-auto mt-3'>
            {/* // <table className='my-3'>
        //     <thead className='text-black text-sm'> */}
            {/* <tr className="whitespace-nowrap text-center rounded-md">
                    <th scope="col" className="w-1/4 p-1" >Customer</th>
                    <th scope="col" className="w-1/3 p-1" >
                        {employee.role === 2 ? "Assigned To" : "Created On"}</th>
                    <th scope="col" className="w-1/4 p-1" >Last Action</th>
                    <th scope="col" className="w-1/4 p-1" >Duration</th>
                </tr> */}
            {/* // </thead>
            // <tbody> */}
            {data?.length === 0 ?
                noData() :
                data.map((data, i) => (
                    <div key={i} className={`grid grid-cols-8 text-xs customers-card items-center w-full rounded-md m-1 border-gray-500`}>
                        {/* <td className="text-xs">{data.customer}</td>
                                <td className={`text-xs`}>
                                    {employee.role === 2 ? data.emp : moment(data.createdAt).format("DD-MM-YYYY")}</td>
                                <td className="text-xs">{moment(data.updatedAt).format("DD-MM-YYYY")}</td>
                                <td className="text-xs pt-1">{daysDiff(data.updatedAt, data.createdAt)}</td> */}
                        <div className={`col-span-3 pl-2 flex items-center gap-1`}>
                            <div className={`w-2 h-2 rounded-full ${data.dealStatus === 4 ? "bg-red-700" : data.dealStatus === 5 ? "bg-green-700" : "hidden"}`}></div>
                            <p>{data.customer}</p>
                        </div>
                        <div className="col-span-4">{employee.role === 2 ? data.emp : moment(data.createdAt).format("DD-MM-YYYY")}</div>
                        {/* <div className='col-span-2'>{moment(data.updatedAt).format("DD-MM-YYYY")}</div> */}
                        <div className='col-span-1 '>{daysDiff(data.updatedAt, data.createdAt)} d</div>
                    </div>
                ))}
            {/* //     </tbody>
        // </table> */}
        </div>
    );
};

export default TableContent;