import { useState, useEffect } from 'react'
import './Menu.css';
import LogoutPopup from '../PopUps/LogoutPopup';
import logout from '../images/logout.svg';
import newcustomer from '../images/New Customer.svg';
import customerdetails from '../images/Customer Details.svg';
import customerevaluation from '../images/Customer Evaluation.svg';
import todo from '../images/todo list.svg';
import deals from '../images/deals.svg';
import reward from '../images/rewards2.svg';

export default function Menu({ employee, section, setSection, custLength, custLengthEval, rewardsCount }) {
    const [showPopup, setShowPopup] = useState(false);

    const togglePopup = () => {
        setShowPopup(!showPopup)
    }
    useEffect(() => {
        console.log("employee", employee, section)
    }, []);

    return (
        <div>

            {showPopup && <LogoutPopup closePopup={togglePopup} employee={employee} />}
            {employee.role === 2 ?
                <div className='bottom_bar rounded-t-2xl mt-3 sm:text-sm md:text-base text-xs flex justify-around items-center text-center'>
                    <div className={`${section === "AddCustomer" ? "bg-gray-500 rounded-xl" : "bg-inherit rounded-xl"} w-10 p-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => { setSection("AddCustomer"); (console.log("sec", section)) }}><img src={newcustomer} className='w-20' /></div>
                    <div className={`relative ${section === "ViewCustomers" ? "bg-gray-500 rounded-xl" : "bg-inherit rounded-xl"} w-10 p-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("ViewCustomers")}>
                        <img src={customerdetails} className='w-20' />
                        {custLength > 0 ?
                            <div className='text-sm min-w-[1rem] min-h-[1rem] bg-red-600 rounded-full text-center items-center justify-center absolute bottom-0 right-0'>
                                <div className='text-xs'>{custLength}</div>
                            </div> : ""}
                    </div>
                    <div className={`${section === "Employees" ? "bg-gray-500 rounded-xl" : "bg-inherit rounded-xl"} w-10 p-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => { setSection("Employees"); (console.log("sec", section)) }}><i className="fa fa-users fa-2x" aria-hidden="true"></i></div>
                    <div className={`${section === "Deals" ? "bg-gray-500 rounded-xl" : "bg-inherit rounded-xl"} w-10 p-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => { setSection("Deals") }}><img src={deals} className='w-20' /></div>
                    <div className={`${section === "ToDo" ? "bg-gray-500 rounded-xl" : "bg-inherit rounded-xl"} w-10 p-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => { setSection("ToDo"); (console.log("sec", section)) }}><img src={todo} className='w-20' /></div>
                    <div className={`${section === "Logout" ? "bg-gray-500 rounded-xl" : "bg-inherit rounded-xl"} p-2 w-10 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => togglePopup()}> <img src={logout} alt='' className='w-8 h-6 cursor-pointer' /></div>
                </div> :
                <div className='bottom_bar rounded-t-2xl mt-3 sm:text-sm md:text-base text-xs flex justify-around items-center text-center'>
                    <div className={`relative ${section === "Customers" ? "bg-gray-500 rounded-xl" : "bg-inherit rounded-xl"} w-10 p-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Customers")}>
                        <img src={customerdetails} className='w-20' />
                        {custLength > 0 ?
                            <div className='text-sm min-w-[1rem] min-h-[1rem] bg-red-600 rounded-full text-center items-center justify-center absolute bottom-0 right-0'>
                                <div className='text-xs'>{custLength}</div>
                            </div> : ""}
                    </div>
                    <div className={`relative ${section === "CustomerEvaluation" ? "bg-gray-500 rounded-xl" : "bg-inherit rounded-xl"} w-10 p-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("CustomerEvaluation")}>
                        <img src={customerevaluation} className='w-20' />
                        {custLengthEval > 0 ?
                            <div className='text-sm min-w-[1rem] min-h-[1rem] bg-red-600 rounded-full text-center items-center justify-center absolute bottom-0 right-0'>
                                <div className='text-xs'>{custLengthEval}</div>
                            </div>
                            : ""}
                    </div>
                    <div className={`relative ${section === "Rewards" ? "bg-gray-500 rounded-xl" : "bg-inherit rounded-xl"} w-10 p-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => { setSection("Rewards") }}>
                        <img src={reward} className='w-16 h-6' />
                        {rewardsCount > 0 ?
                            <div className='text-sm min-w-[1rem] min-h-[1rem] bg-red-600 rounded-full text-center items-center justify-center absolute bottom-0 right-0'>
                                <div className='text-xs'>{rewardsCount}</div>
                            </div> : ""}
                    </div>
                    <div className={`${section === "Employees" ? "bg-gray-500 rounded-xl" : "bg-inherit rounded-xl"} w-10 p-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => { setSection("Employees"); (console.log("sec", section)) }}><img src={deals} className='w-20' /></div>
                    <div className={`${section === "ToDo" ? "bg-gray-500 rounded-xl" : "bg-inherit rounded-xl"} w-10 p-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => { setSection("ToDo"); (console.log("sec", section)) }}><img src={todo} className='w-20' /></div>
                    <div className={`${section === "Logout" ? "bg-gray-500 rounded-xl" : "bg-inherit rounded-xl"} p-2 w-10 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => togglePopup()}> <img src={logout} alt='' className='w-8 h-6 cursor-pointer' /></div>
                </div>}
        </div>
    )
}