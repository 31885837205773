import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Button, Row, Col, Card } from "react-bootstrap";
import { config } from '../Config/Config';
import SimpleCrypto from 'simple-crypto-js';
import EmployeeContext from '../context/employee/EmpContext';
import moment from "moment-timezone";
import { enqueueSnackbar } from 'notistack'

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const CustomerDetails = ({ setSection, getAllCustomers }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const custData = location.state.data;
    const customer = custData;
    const { empState: employee } = useContext(EmployeeContext);
    const [leadTypes, setLeadTypes] = useState([]);
    const [place, setPlace] = useState([]);
    const [errors, setErrors] = useState({});
    const [genders, setGenders] = useState([]);
    const [availableFields, setAvailableFields] = useState([]);
    const [ageRanges, setAgeRanges] = useState([]);
    const [incomeRanges, setIncomeRanges] = useState([]);
    const [occupations, setOccupations] = useState([]);
    const [maritalStatusValues, setMaritalStatusValues] = useState([]);
    const [userTypes, setUserTypes] = useState([]);
    const [insuranceTypes, setInsuranceTypes] = useState([]);
    const [inputValues, setInputValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        leadType: 1,
        region: 1,
        gender: '',
        age: '',
        income: '',
        occupation: '',
        maritalStatus: '',
        companyName: '',
        userType: '',
        annualIncome: "",
        dependents: "",
        otherIncome: "",
        loanAmount: "",
        tenure: "",
        insuranceType: "",
        insuredValue: ""
    });

    useEffect(() => {
        getAllParameters();
        setSection("CustomerDetails")
        console.log("customer details", custData);
        let currentValues = { ...inputValues };
        currentValues = { ...currentValues, ["firstName"]: customer.firstName || "" };
        currentValues = { ...currentValues, ["lastName"]: customer.lastName || "" };
        if (customer?.email)
            currentValues = { ...currentValues, ["email"]: simpleCrypto.decrypt(customer.email) };
        if (customer?.mobile)
            currentValues = { ...currentValues, ["mobile"]: simpleCrypto.decrypt(customer.mobile) };
        if (customer && customer.leadType) {
            currentValues = { ...currentValues, ["leadType"]: customer.leadType };
        }
        if (customer && customer.region) {
            currentValues = { ...currentValues, ["region"]: customer.region };
        }
        if (customer && customer.gender) {
            currentValues = { ...currentValues, ["gender"]: customer.gender };
        }
        if (customer && customer.ageRange) {
            currentValues = { ...currentValues, ["age"]: customer.ageRange };
        }
        if (customer && customer.incomeRange) {
            currentValues = { ...currentValues, ["income"]: customer.incomeRange };
        }
        if (customer && customer.occupation) {
            currentValues = { ...currentValues, ["occupation"]: customer.occupation };
        }
        if (customer && customer.maritalStatus) {
            currentValues = { ...currentValues, ["maritalStatus"]: customer.maritalStatus };
        }
        if (customer && customer.companyName) {
            currentValues = { ...currentValues, ["companyName"]: customer.companyName };
        }
        if (customer && customer.userType) {
            currentValues = { ...currentValues, ["userType"]: customer.userType };
        }
        if (customer && customer.annualIncome) {
            currentValues = { ...currentValues, ["annualIncome"]: customer.userType };
        }
        if (customer && customer.dependents) {
            currentValues = { ...currentValues, ["dependents"]: customer.userType };
        }
        if (customer && customer.loanAmount) {
            currentValues = { ...currentValues, ["loanAmount"]: customer.userType };
        }
        if (customer && customer.otherIncome) {
            currentValues = { ...currentValues, ["otherIncome"]: customer.userType };
        }
        if (customer && customer.tenure) {
            currentValues = { ...currentValues, ["tenure"]: customer.userType };
        }
        if (customer && customer.insuranceType) {
            currentValues = { ...currentValues, ["insuranceType"]: customer.userType };
        }
        if (customer && customer.insuredValue) {
            currentValues = { ...currentValues, ["insuredValue"]: customer.userType };
        }
        setInputValues({ ...inputValues, ...currentValues })
        getAvailableFields();
    }, []);
    const getAllParameters = () => {
        axios.get(config.apiUrl + 'employees/getAllParameters')
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message);
                    setLeadTypes(response.data.leadTypes);
                    setPlace(response.data.region)
                    setGenders(response.data.gender);
                    setAgeRanges(response.data.ageRange);
                    setIncomeRanges(response.data.incomeRange);
                    setOccupations(response.data.occupation);
                    setMaritalStatusValues(response.data.maritalStatus);
                    setUserTypes(response.data.userTypes);
                    setInsuranceTypes(response.data.insuranceTypes)
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const getAvailableFields = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'employees/getAvailableFieldsByCompanyId', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success available fields", response.data);
                    setAvailableFields(response.data.availableFields);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const isAvailable = (field) => {
        let value = false;
        availableFields && availableFields.forEach(item => {
            for (let key in item) {
                if (key === field)
                    value = item[key];
            }
        })
        return value;
    }
    const updateCustomerDetails = () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: 'error' })
            return
        };
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "customerId": custData?.customerId,
            "firstName": inputValues.firstName,
            "lastName": inputValues.lastName,
            "email": inputValues.email ? simpleCrypto.encrypt(inputValues.email) : inputValues.email,
            "mobile": inputValues.mobile ? simpleCrypto.encrypt(inputValues.mobile) : inputValues.mobile,
            "leadType": inputValues.leadType ? parseInt(inputValues.leadType) : inputValues.leadType,
            "region": inputValues.region ? parseInt(inputValues.region) : inputValues.region,
            "gender": inputValues.gender ? parseInt(inputValues.gender) : null,
            "ageRange": inputValues.age ? parseInt(inputValues.age) : null,
            "incomeRange": inputValues.income ? parseInt(inputValues.income) : null,
            "occupation": inputValues.occupation ? parseInt(inputValues.occupation) : null,
            "maritalStatus": inputValues.maritalStatus ? parseInt(inputValues.maritalStatus) : null,
            "activityId": custData.activityId,
            "activityStatus": custData.activityStatus === 3 ? custData.activityStatus : 2,
            "companyName": inputValues.companyName,
            "userType": inputValues.userType ? inputValues.userType : null,
            "annualIncome": inputValues.annualIncome ? inputValues.annualIncome : null,
            "dependents": inputValues.dependents ? inputValues.dependents : null,
            "otherIncome": inputValues.otherIncome ? inputValues.otherIncome : null,
            "loanAmount": inputValues.loanAmount ? inputValues.loanAmount : null,
            "tenure": inputValues.tenure ? inputValues.tenure : null,
            "insuranceType": inputValues.insuranceType ? inputValues.insuranceType : null,
            "insuredValue": inputValues.insuredValue ? inputValues.insuredValue : null,
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'employees/addCustomerDetails', payload, { headers: headers })
            .then((response) => {
                console.log(response);
                setSection("Customers")
                enqueueSnackbar("Customer Details Added Successfully!", { variant: 'success' })
                navigate(`/relationshipmanager/Customers}`);//changed subdomain to /
                getAllCustomers();
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });

    };
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!inputValues.firstName) {
            errorObj = {
                ...errorObj,
                firstNameError: true
            }
            error = true;
        }
        if (!inputValues.lastName) {
            errorObj = {
                ...errorObj,
                lastNameError: true
            };
            error = true;
        }
        if (!inputValues.email) {
            errorObj = {
                ...errorObj,
                emailError: true
            }
            error = true;
        }
        if (!inputValues.mobile) {
            errorObj = {
                ...errorObj,
                mobileError: true
            }
            error = true;
        }
        if (!inputValues.leadType) {
            errorObj = {
                ...errorObj,
                leadTypeError: true
            }
            error = true;
        }
        if (!inputValues.region) {
            errorObj = {
                ...errorObj,
                regionError: true
            }
            error = true;
        }
        if (employee.businessType === 1 && !inputValues.companyName) {
            errorObj = {
                ...errorObj,
                cNameError: true
            }
            error = true;
        }
        if (employee.businessType === 1 && !inputValues.userType) {
            errorObj = {
                ...errorObj,
                uTypeError: true
            }
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    };
    const handleInputChange = (event) => {
        setInputValues({ ...inputValues, [event.target.name]: event.target.value });
    }
    return (
        <div className='ml-2 mr-2'>
            <div>
                <center className='mt-1 text-lg font-semibold'>Customer Details</center>
                <div className='max-h-[70vh] overflow-y-auto'>
                    <input className="text-xs w-full px-4 py-2 border border-solid border-gray-300 rounded mt-1"
                        type="text"
                        value={inputValues.firstName}
                        onChange={(e) => { handleInputChange(e); setErrors({ ...errors, firstNameError: false }) }}
                        name="firstName"
                        placeholder="First Name" />
                    <input className="text-xs w-full px-4 py-2 border border-solid border-gray-300 rounded mt-1"
                        type="text"
                        value={inputValues.lastName}
                        onChange={(e) => { handleInputChange(e); setErrors({ ...errors, lastNameError: false }) }}
                        name="lastName"
                        placeholder="Last Name" />
                    {customer && customer.email ?
                        <p className={`text-sm w-full h-9 bg-white px-4 py-2 ${errors.emailError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}>{"*".repeat(10)}</p>
                        : <input className="text-xs w-full px-4 py-2 border border-solid border-gray-300 rounded mt-1"
                            type="text"
                            value={inputValues.email}
                            onChange={(e) => { handleInputChange(e); setErrors({ ...errors, emailError: false }) }}
                            name="email"
                            placeholder="Email" />}
                    {customer && customer.mobile ?
                        <p className={`text-sm w-full h-9 bg-white px-4 py-2 ${errors.emailError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}>{"*".repeat(10)}</p>
                        : <input className="text-xs w-full px-4 py-2 border border-solid border-gray-300 rounded mt-1"
                            type="text"
                            value={inputValues.mobile}
                            onChange={(e) => { handleInputChange(e); setErrors({ ...errors, mobileError: false }) }}
                            name="mobile"
                            placeholder="Mobile" />}
                    <select name="userType" value={inputValues.userType} className={`text-xs w-full px-4 py-2 border-[1px] border-gray-300 rounded mt-1`}
                        onChange={(e) => { handleInputChange(e) }} >
                        <option value="">--Select User Type--</option>
                        {userTypes && userTypes.map(item => {
                            return (
                                <option value={item.id} key={item.id}>{item.userType}</option>
                            )
                        })}
                    </select>
                    <input className={`text-xs w-full px-4 py-2 border border-solid border-gray-300 rounded mt-1 ${inputValues.userType != 1 ? "hidden" : ""}`}
                        type="text"
                        value={inputValues.companyName}
                        onChange={(e) => { handleInputChange(e) }}
                        name="companyName"
                        placeholder="Company Name" />
                    <select name="gender" value={inputValues.gender} className={`text-xs w-full px-4 py-2 border-[1px] border-gray-300 ${isAvailable('gender') ? "" : "hidden"} rounded mt-1`}
                        onChange={(e) => { handleInputChange(e); setErrors({ ...errors, genderError: false }) }} >
                        <option value="">--Select Gender--</option>
                        {genders && genders.map(gender => {
                            return (
                                <option value={gender.id} key={gender.id}>{gender.gender}</option>
                            )
                        })}
                    </select>
                    <select name="age" value={inputValues.age} className={`text-xs w-full px-4 py-2 border-[1px] border-gray-300 ${isAvailable('age') ? "" : "hidden"} rounded mt-1`}
                        onChange={(e) => { handleInputChange(e) }} >
                        <option value="">--Select Age Range--</option>
                        {ageRanges && ageRanges.map(item => {
                            return (
                                <option value={item.id} key={item.id}>{item.age}</option>
                            )
                        })}
                    </select>
                    <select name="maritalStatus" value={inputValues.maritalStatus} className={`text-xs w-full px-4 py-2 border-[1px] border-gray-300 ${isAvailable('maritalStatus') ? "" : "hidden"} rounded mt-1`}
                        onChange={(e) => { handleInputChange(e) }} >
                        <option value="">--Select Marital Status--</option>
                        {maritalStatusValues && maritalStatusValues.map(item => {
                            return (
                                <option value={item.id} key={item.id}>{item.maritalStatus}</option>
                            )
                        })}
                    </select>
                    <select name="occupation" value={inputValues.occupation} className={`text-xs w-full px-4 py-2 border-[1px] border-gray-300 ${isAvailable('occupation') ? "" : "hidden"} rounded mt-1`}
                        onChange={(e) => { handleInputChange(e) }} >
                        <option value="">--Select Occupation--</option>
                        {occupations && occupations.map(item => {
                            return (
                                <option value={item.id} key={item.id}>{item.occupation}</option>
                            )
                        })}
                    </select>
                    <select name="income" value={inputValues.income} className={`text-xs w-full px-4 py-2 border-[1px] border-gray-300 ${isAvailable('income') ? "" : "hidden"} rounded mt-1`}
                        onChange={(e) => { handleInputChange(e) }} >
                        <option value="">--Select Income Range--</option>
                        {incomeRanges && incomeRanges.map(item => {
                            return (
                                <option value={item.id} key={item.id}>{item.income}</option>
                            )
                        })}
                    </select>
                    <select name="leadType" value={inputValues.leadType} className={`text-xs w-full px-4 py-2 ${errors.leadTypeError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-1`}
                        onChange={(e) => { handleInputChange(e); setErrors({ ...errors, leadTypeError: false }) }} >
                        <option value="">--Select Lead Type--</option>
                        {leadTypes && leadTypes.map(leadType => {
                            return (
                                <option value={leadType.id} key={leadType.id}>{leadType.leadType}</option>
                            )
                        })}
                    </select>
                    <select name="region" value={inputValues.region} className={`text-xs w-full px-4 py-2 ${errors.regionError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-1`}
                        onChange={(e) => { handleInputChange(e); setErrors({ ...errors, regionError: false }) }} >
                        <option value="">--Select Region--</option>
                        {place && place.map(item => {
                            return (
                                <option value={item.id} key={item.id}>{item.place}</option>
                            )
                        })}
                    </select>
                    {/* { className="text-xs w-full px-4 py-2 border border-solid border-gray-300 rounded mt-1"} */}
                    <input className={`text-xs bg-white w-full px-4 py-2 border border-solid border-gray-300 ${isAvailable('annualIncome') ? "" : "hidden"} rounded mt-1`}
                        type="text"
                        onChange={(e) => { handleInputChange(e) }}
                        value={inputValues.annualIncome}
                        name="annualIncome"
                        placeholder="Annual income" />
                    <input className={`text-xs bg-white w-full px-4 py-2 border border-solid border-gray-300 ${isAvailable('dependents') ? "" : "hidden"} rounded mt-1`}
                        type="text"
                        onChange={(e) => { handleInputChange(e) }}
                        value={inputValues.dependents}
                        name="dependents"
                        placeholder="Dependents" />
                    <input className={`text-xs bg-white w-full px-4 py-2 border border-solid border-gray-300 ${isAvailable('otherIncome') ? "" : "hidden"} rounded mt-1`}
                        type="text"
                        onChange={(e) => { handleInputChange(e) }}
                        value={inputValues.otherIncome}
                        name="otherIncome"
                        placeholder="Other income" />
                    <input className={`text-xs bg-white w-full px-4 py-2 border border-solid border-gray-300 ${isAvailable('loanAmount') ? "" : "hidden"} rounded mt-1`}
                        type="text"
                        onChange={(e) => { handleInputChange(e) }}
                        value={inputValues.loanAmount}
                        name="loanAmount"
                        placeholder="Loan Amount" />
                    <input className={`text-xs bg-white w-full px-4 py-2 border border-solid border-gray-300 ${isAvailable('tenure') ? "" : "hidden"} rounded mt-1`}
                        type="text"
                        onChange={(e) => { handleInputChange(e) }}
                        value={inputValues.tenure}
                        name="tenure"
                        placeholder="Loan Tenure" />
                    <select name="insuranceType" value={inputValues.insuranceType} className={`text-xs w-full px-4 py-2 border-[1px] border-gray-300 ${isAvailable('insuranceType') ? "" : "hidden"} rounded mt-1`}
                        onChange={(e) => { handleInputChange(e) }} >
                        <option value="">--Select Insurance Type--</option>
                        {insuranceTypes && insuranceTypes.map(item => {
                            return (
                                <option value={item.id} key={item.id}>{item.insurance}</option>
                            )
                        })}
                    </select>
                    <input className={`text-xs bg-white w-full px-4 py-2 border border-solid border-gray-300 ${isAvailable('insuredValue') ? "" : "hidden"} rounded mt-2`}
                        type="text"
                        onChange={(e) => { handleInputChange(e) }}
                        value={inputValues.insuredValue}
                        name="insuredValue"
                        placeholder="Insured Value" />
                </div>
                <div className="flex justify-between items-center text-center md:text-left">
                    <div></div>
                    <button onClick={updateCustomerDetails}
                        className="mt-2 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color h-10 px-3 py-2 font-semibold uppercase rounded text-sm"
                        type="submit"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CustomerDetails