import React from 'react'
import logo from '../images/logo.svg';
import { Link } from "react-router-dom";

const NavMenu = () => {
    return (
        <div className="sticky top-0 flex w-full rounded-b-xl h-16 items-center justify-left text-sm bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color">
            <div className='justify-left items-center ml-3 w-12 cursor-pointer'><Link to="/"><img src={logo} /></Link></div>
            <div className="w-full text-xl font-semibold text-center transform -translate-x-6">Entropi</div>
        </div>
        // <div className="flex w-full h-16 rounded-b-xl items-center justify-left text-sm bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color sticky top-0">
        // <div className='justify-left items-center ml-2 w-12 cursor-pointer'><img src={logo} /></div>
        // <div className="text-xl font-semibold w-[90vw] text-center items-center">Entropi</div>
    // </div>
    )
}

export default NavMenu