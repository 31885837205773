import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import logo from "../images/logo.svg";
import axios from "axios";
import { config } from "../Config/Config";
import SimpleCrypto from "simple-crypto-js";
import NavMenu from "../Login/NavMenu";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function Terms() {
    const { companyname, id: uid } = useParams();
    const [companyDetails, setCompanyDetails] = useState();
    const [terms, setTerms] = useState();
    const [conditions, setConditions] = useState();

    useEffect(() => {
        if (sessionStorage.getItem("cName")) {
            setCompanyDetails(simpleCrypto.decrypt(sessionStorage.getItem("cName")));
            getTermsAndConditions();
        }
    }, [])
    // useEffect(() => {
    //     if (companyDetails) {
    //         getTermsAndConditions();
    //     }
    // }, [companyDetails])
    const getTermsAndConditions = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var payload = {
            "companyId": companyDetails?.id
        }
        console.log("payload", payload, companyDetails)
        axios.post(config.apiUrl + 'companyAdmin/getCompanyTermsAndCondition', payload, headers)
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message);
                    if (response.data.terms) {
                        setTerms(response.data.terms.terms);
                        setConditions(response.data.terms.conditions);
                    }
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    return (
        <div className="h-screen flex flex-col">
            <NavMenu />
            {/* <section className="mt-4">
                <div className="max-w-xl h-[45vh]">
                    <center className="text-2xl font-semibold">{companyDetails && companyDetails.name}</center>
                    <p className="mt-2 ml-4 text-lg">Terms & Conditions</p>
                    {terms ?
                        <div className="ml-4 mt-2 text-base">
                            <p>{terms}</p>
                            <p>{conditions}</p>
                        </div> :
                        <div className="ml-4 mt-2 text-base">
                            <p>Thanks for exploring opportunity to be part of Lead Management club. We believe in creating a cohesive
                                and collaborative environment to learn and share knowledge covering
                                both its breadth and depth.</p>
                        </div>}
                </div>
            </section> */}

            <section className="mt-3 justify-around items-center">
                <div className="max-w-xl">
                </div>
                <div className="max-w-xl h-[45vh]">
                    <center className="text-2xl font-semibold">{companyDetails && companyDetails.name}</center>
                    <p className="mt-2 ml-4 text-lg">Terms & Conditions</p>
                    {terms ?
                        <div className="ml-4 mt-2 text-base">
                            <p>{terms}</p>
                            <p>{conditions}</p>
                        </div> :
                        <div className="ml-4 mt-2 text-base">
                            <p>Thanks for exploring opportunity to be part of Lead Management club. We believe in creating a cohesive
                                and collaborative environment to learn and share knowledge covering
                                both its breadth and depth.</p>
                        </div>}
                </div>
            </section>
        </div >
    )
}
