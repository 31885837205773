import { Route, Routes } from 'react-router-dom';
import React from 'react';
import Login from './Login/Login.js';
import ResetPassword from './Login/ResetPassword.js';
import SignUp from './SignUp/SignUp.js';
import Home from './Home/Home.js';
import BranchAdmin from './BranchAdmin/BranchAdmin.js';
import RelationshipManager from './RelationshipManager/RelationshipManager.js';
import Terms from './SignUp/Terms.js';
import { CompanyAdmin } from './components/CompanyAdmin/CompanyAdmin.js';

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/branchadmin/:part" element={<BranchAdmin />} />
            <Route path="/relationshipmanager/:part" element={<RelationshipManager />} />
            <Route path="/admin/:part" element={<CompanyAdmin />} />
        </Routes>
    )
}

export default AppRoutes;