import React, { useContext } from 'react'
import { Card, Modal } from 'react-bootstrap'
import EmployeeContext from '../context/employee/EmpContext'
import appreciationcertificate from "../images/appreciation-cert.svg"
import "./CertificatePopup.css";
import badgelogo from "../images/badge.svg"

const CertificatePopup = ({ togglePopup, data }) => {
    const { empState: employee } = useContext(EmployeeContext)
    return (
        <Modal show={true} onHide={togglePopup} size="md" centered contentClassName='rounded-2xl'>
            <Modal.Body>
                <Card className="bg-white rounded-none m-1 border-4 border-black w-[97.5%] h-[71vh]">
                    <Card className="bg-white rounded-none m-auto w-[96.6%] h-[69vh]"><br />
                        <center>
                            <img src={employee.logo} className="w-[30%] -mt-[5%]" />
                            <div className="certificate">Certificate</div>
                            <div className="certificate-of">of</div>
                            <img src={appreciationcertificate} className="appreciation-certificate" />
                            <div className="certificate-cName">{data.employeeName}</div>
                            <div className="certificate-completed"><span className="normal-weight">Has been awarded</span></div>
                            <div className="title-and-version"><span className="normal-weight">with the </span><span className="weight"><i>{data.reward} ({data.branchName})</i></span><span className="normal-weight"> for the month of </span></div>
                            <div className="certificate-on"><span className="weight"> <i>{data.monthAndYear}</i></span><span className="normal-weight"> on </span><span className="title-and-version weight color-blue"><i>{data.createdAt.split("T")[0].split("-").reverse().join("-")}</i></span></div></center>
                        <img src={badgelogo} className="badge-logo-sceem" />
                    </Card>
                </Card>
            </Modal.Body>
        </Modal>
    )
}

export default CertificatePopup