import React from "react";
import nebutech from "../images/nebutech.svg";

export default function NebutechLogo() {
    return (
        <div className='absolute bottom-3 right-3'>
            <div className='font-semibold'>
                <img src={nebutech} className='w-20 h-12' />
            </div>
        </div>
    )
}
