import React, { useState, useEffect } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import SuccessPopup from "../PopUps/SuccessPopup";
import ErrorPopup from "../PopUps/ErrorPopup";
import { Link, useNavigate, useParams } from "react-router-dom";
import logo from "../images/logo.svg";
import picture from "../images/lsm picture.svg";
import NebutechLogo from "../NebutechLogo/NebutechLogo";
import { enqueueSnackbar } from "notistack";
import NavMenu from "../Login/NavMenu";
var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const validPasswordRegex =
    /^.*(?=.{10,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!^*]).*$/
const validPhoneNoRegex = RegExp(/^\d{10}$/);

const SignUp = () => {
    const { companyname, id: uid } = useParams();
    const navigate = useNavigate();
    const [mobile, setMobile] = useState("")
    const [mobileError, setMobileError] = useState("")
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState("")
    const [confirmPasswordError, setConfirmPasswordError] = useState("")
    const [show, setShow] = useState(false);
    const [showError, setShowError] = useState(false);
    const [msg, setMsg] = useState('');
    const [checked, setChecked] = useState(false)
    const [uniqueCode, setUniqueCode] = useState('');
    const [companyDetails, setCompanyDetails] = useState();
    const [employeeId, setEmployeeId] = useState('');
    const [employeeIdError, setEmployeeIdError] = useState("")

    useEffect(() => {
        const host = window.location.host;
        const arr = host.split("-").slice(0, 2);
        if (!arr[1]) return;
        let code = arr[1].split(".")[0]
        console.log("host", host, arr, code)//changed splitting method for new subdomain
        setUniqueCode(code);

        if (sessionStorage.getItem('cName')) {
            setCompanyDetails(simpleCrypto.decrypt(sessionStorage.getItem('cName')))
        }
    }, [])
    const handleBlur = (event) => {
        const { name } = event.target;
        validateField(name);
        return;
    }
    const handleCheck = () => {
        setChecked(!checked)
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let formFileds = [
            "mobile",
            "password",
            "confirmPassword",
            "employeeId"
        ];
        let isValid = true;
        formFileds.forEach(field => {
            isValid = validateField(field) && isValid;
        });
        if (!isValid) {
            console.log("Please enter the details")
            enqueueSnackbar('Please enter valid the details!', { variant: 'error' })//chnagd alert 
        } else if (!checked) {
            enqueueSnackbar('Please agree to the Terms!', { variant: 'error' })//chnagd alert 
        } else
            signUp();
    }
    const signUp = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var payload = {
            "mobile": mobile,
            "password": simpleCrypto.encrypt(password),
            "uniqueCode": uniqueCode,
            "employeeId": employeeId && employeeId.toUpperCase()
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'employees/register', payload)
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, payload);
                    setMsg(response.data.message);
                    setShow(true);
                    setTimeout(() => {
                        setMsg(''); setShow(false);
                        navigate("/")//changed subdomain here 
                    }, 2000)
                } else {
                    console.log("Error", payload);
                    setMsg(response.data.message);
                    setShowError(true);
                    setTimeout(() => { setMsg(''); setShowError(false) }, 2000)
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    const validateField = (name) => {
        let isValid = false;
        if (name === "mobile") isValid = validateMobile();
        else if (name === "password") isValid = validatePassword();
        else if (name === "confirmPassword") isValid = validateConfirmPassword();
        else if (name === "employeeId") isValid = validateEmployeeId();
        return isValid;
    }
    const validateMobile = () => {
        let mobileError = "";
        const value = mobile;
        if (value.trim === "") mobileError = "Please enter valid phone number";
        else if (!validPhoneNoRegex.test(value))
            mobileError = "Please enter valid phone number";
        setMobileError(mobileError)
        return mobileError === "";
    }

    const validatePassword = () => {
        let passwordError = "";
        const value = password;
        if (value.trim === "") passwordError = "Password is required";
        else if (!validPasswordRegex.test(value))
            passwordError = "Min 10 with 1 capital, 1 numeric and 1 character (!@#$%^&*)";
        setPasswordError(passwordError)
        return passwordError === "";
    }

    const validateConfirmPassword = () => {
        let confirmPasswordError = "";
        if (password !== confirmPassword)
            confirmPasswordError = "Password does not match";
        setConfirmPasswordError(confirmPasswordError)
        return confirmPasswordError === "";
    }
    const validateEmployeeId = () => {
        let empIdError = "";
        const value = employeeId;
        if (value === "") empIdError = "Employee ID is required";
        setEmployeeIdError(empIdError)
        return empIdError === "";
    }

    return (
        <div className="h-screen flex flex-col">
            <NavMenu />
            <section className="my-4 justify-around items-center px-10 min-h-[75vh] max-h-[80vh] overflow-y-scroll">
                <SuccessPopup show={show} setShow={setShow} msg={msg} />
                <ErrorPopup show={showError} setShow={setShowError} msg={msg} />
                <div className=" max-w-xl">
                    <img src={picture} />
                </div>
                <div className="max-w-xl min-h-[45vh] max-h-max">
                    <div className="flex flex-col items-center text-center md:text-left">
                        <p className='text-xl font-semibold'>{companyDetails && companyDetails.name}</p>
                        <img src={companyDetails && companyDetails.logo} alt='' className='w-9 h-9' />
                        <p className="mt-2 font-semibold mr-1 text-lg">Register</p>
                    </div>
                    <div>
                        <input className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-2"
                            type="text"
                            placeholder="Employee ID"
                            name="employeeId"
                            onChange={(e) => { setEmployeeId(e.target.value); setEmployeeIdError(''); }}
                            onBlur={handleBlur} />
                        <div className='error'>
                            {employeeIdError && (<div className="error">{employeeIdError}</div>)}
                        </div>
                    </div>
                    <div>
                        <input className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-3"
                            type="text"
                            placeholder="Mobile Number"
                            name="mobile"
                            onChange={(e) => { setMobile(e.target.value); setMobileError('') }}
                            onBlur={handleBlur} />
                    </div>
                    <div className='error'>{mobileError && (
                        <div className="error">{mobileError}</div>
                    )}</div>
                    <div>
                        <input className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-3"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            onChange={(e) => { setPassword(e.target.value); setPasswordError('') }}
                            onBlur={handleBlur}
                            placeholder="Password" />
                        <span className="sticky float-right -mt-9 p-1 mr-2 cursor-pointer"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <i className="fa fa-eye" aria-hidden="true"></i> : <i className="fa fa-eye-slash" aria-hidden="true"></i>}
                        </span>
                        <div className='error'>{passwordError && (
                            <div className="error">{passwordError}</div>
                        )}</div>
                    </div>
                    <div>
                        <input className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-3"
                            type={showConfirmPassword ? "text" : "password"}
                            name="confirmPassword"
                            onChange={(e) => { setConfirmPassword(e.target.value); setConfirmPasswordError('') }}
                            onBlur={handleBlur}
                            placeholder="Confirm Password" />
                        <span className="sticky float-right -mt-9 p-1 mr-2 cursor-pointer"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        >
                            {showConfirmPassword ? <i className="fa fa-eye" aria-hidden="true"></i> : <i className="fa fa-eye-slash" aria-hidden="true"></i>}
                        </span>
                        <div className='error'>{confirmPasswordError && (
                            <div className="error">{confirmPasswordError}</div>
                        )}</div>
                    </div>
                    <div className="flex text-xs mt-3"><input type="checkbox" checked={checked} onChange={handleCheck} /> <span className="ml-1 text-xs">I agree to the
                        <Link to={`/terms`}><span className="text-sky-500 hover:text-sky-500">Terms & Conditions </span></Link>
                    </span></div><br />
                    <div className="-mt-2 flex justify-between items-center text-center md:text-left">
                        <div></div>
                        <button className="h-10 btn-block bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-base" type="submit" onClick={handleSubmit}>SignUp</button>
                    </div>
                    <div className="mt-3 font-semibold text-sm text-black text-center md:text-left">
                        Already have an account ?
                        <Link className="text-sky-500 hover:underline hover:underline-offset-4" to={`/`}>&nbsp;Login</Link>
                    </div>
                </div>
            </section>
            <NebutechLogo />
        </div>
    )
}

export default SignUp