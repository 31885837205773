import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import SimpleCrypto from "simple-crypto-js";
import { enqueueSnackbar } from 'notistack';
import EmployeeContext from "../context/employee/EmpContext";


var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


const TodoPopup = ({ data, closePopup, getTodaysToDos }) => {
    const navigate = useNavigate();
    const { empState: employee } = useContext(EmployeeContext)
    const [show, setShow] = useState(true);
    const [msg, setMsg] = useState('');
    const [activeEmployees, setActiveEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState()

    useEffect(() => {
        console.log("todos data", data);
        getAllEmployeesByBranch();
    }, [])
    const getAllEmployeesByBranch = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'manager/getAllEmployeesByBranch', { headers: headers })
            .then((response) => {
                if (response.data.status == true) {
                    console.log("success employees fetched form query", response.data);
                    let curActiveEmps = response.data.employees.filter(emp => emp.status === 1 && emp.role === 3 && emp.employeeId !== data.employeeId)
                    setActiveEmployees(curActiveEmps);
                } else {
                    console.log("Error");
                }
            }).catch(error => {
                console.error('Error loading employees data:', error);
            })
    };
    const changeEmployeeId = () => {
        if (!selectedEmployee) {
            enqueueSnackbar("Please select an employee", { variant: "error" });
            return;
        }
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload;
        if (data.reachOutId) {
            payload = {
                reachOutId: data.reachOutId,
                employeeId: selectedEmployee
            }
        } else {
            payload = {
                id: data.id,
                employeeId: selectedEmployee
            }
        }
        axios.post(config.apiUrl + 'manager/updateEmployeedIdOnNotes', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success products", response.data);
                    closePopup();
                    getTodaysToDos();
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const handleChange = (val, func) => {
        func(val);
    }
    return (
        <Modal show={show} onHide={closePopup} size="md" centered contentClassName="rounded-2xl">
            <Modal.Body>
                {data.employeeStatus === 1 || employee.role === 3 ?
                    <section className="mt-5 ml-2 mr-2">
                        <div className="md:w-1/3 max-w-sm -mt-10">
                            <div className="text-center">
                                <label className="mr-1 text-lg text-black-600">{data.employeeName} action for {data.firstName} {data.lastName}</label>
                            </div>
                            <div className="mt-1 flex">
                                <div className={`flex items-center justify-center ml-1 w-5 h-5 rounded-2xl ${data.status === 1 && data.reachOutId ? "bg-reachout-l-green" : data.status === 1 && data.id ? "bg-gray-400" : (data.status === 3 || data.status === 4) && data.id ? "bg-black" : "bg-reachout-d-green"}`}>{data.actionType === 1 ? <><i className="fa fa-phone color-white fa-xs" aria-hidden="true"></i></> : (data.actionType) === 2 ? <><i className="fa fa-envelope color-white fa-xs" aria-hidden="true"></i></> : <><i className="fa fa-user color-white fa-xs" aria-hidden="true"></i></>}</div>
                                <div className="ml-3 w-[35%]">{data?.targetDate ? new Date(data.targetDate).getHours() : ""}:{data?.targetDate ? new Date(data.targetDate).getMinutes() < 10 ? "0" + new Date(data.targetDate).getMinutes() : new Date(data.targetDate).getMinutes() : ""}</div>
                            </div>
                            <div className="mt-1 flex">{data.notes}</div>
                        </div>
                    </section> :
                    <>
                        <div className="flex flex-col w-full justify-center items-center mx-auto mt-6">
                            <div className="text-center">
                                <label className="mr-1 text-lg text-black-600">{data.employeeName} action for {data.firstName} {data.lastName}</label>
                            </div>
                            <div className="mt-1 flex justify-around w-full items-center">
                                <div className={`flex items-center justify-center ml-1 w-5 h-5 rounded-2xl ${data.status === 1 && data.reachOutId ? "bg-reachout-l-green" : data.status === 1 && data.id ? "bg-gray-400" : (data.status === 3 || data.status === 4) && data.id ? "bg-black" : "bg-reachout-d-green"}`}>{data.actionType === 1 ? <><i className="fa fa-phone color-white fa-xs" aria-hidden="true"></i></> : (data.actionType) === 2 ? <><i className="fa fa-envelope color-white fa-xs" aria-hidden="true"></i></> : <><i className="fa fa-user color-white fa-xs" aria-hidden="true"></i></>}</div>
                                <div className="">{data.notes}</div>
                                <div className="">{data?.targetDate ? new Date(data.targetDate).getHours() : ""}:{data?.targetDate ? new Date(data.targetDate).getMinutes() < 10 ? "0" + new Date(data.targetDate).getMinutes() : new Date(data.targetDate).getMinutes() : ""}</div>
                            </div>
                            <div className="grid grid-cols-2 gap-4 mt-3">
                                <div className="flex flex-col">
                                    <label className="text-xs text-gray-600">Employee Id</label>
                                    {data.employeeStatus === 1 ?
                                        <p className="text-xs w-44 text-black p-2 border border-solid border-gray-300 rounded">{data.employeeId}</p>
                                        : <select name="employee" value={selectedEmployee} className={`text-sm w-44 p-2 "border-[1px] border-gray-300" rounded`}
                                            onChange={(e) => { handleChange(e.target.value, setSelectedEmployee); }} >
                                            <option value="">----EmployeeID----</option>
                                            {activeEmployees && activeEmployees.map(item => {
                                                return (
                                                    <option value={item.employeeId} key={item.id}>{item.firstName} {item.lastName}({item.employeeId})</option>
                                                )
                                            })}
                                        </select>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={`flex ${data.employeeStatus === 2 ? "justify-between" : "justify-end"} text-center my-6 mx-12`}>
                            <button className="mt-4 bg-orange-600 hover:bg-orange-700 px-4 py-2 text-white font-semibold uppercase rounded text-xs" type="submit" onClick={closePopup}>Close</button>
                            <button className={`mt-4 bg-black hover:bg-black px-4 py-2 text-white font-semibold uppercase rounded text-xs ${data.employeeStatus === 2 ? "" : "hidden"} `} type="submit" onClick={changeEmployeeId}>Update</button>
                        </div>
                    </>
                }
            </Modal.Body>
        </Modal>
    )
}

export default TodoPopup